import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import CheckboxWithPopout from "../components/nj-macro/CheckboxWithPopout";
import SliderComponent from "../components/nj-macro/SliderComponent";
import EsriMap from "../components/nj-macro/EsriMap";
import ColorBar from "../components/nj-macro/ColorBar";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Dropdown,
  Accordion,
  Button,
  Carousel,
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import "../css/nj-macro/MainWidget.css";
import styled from "styled-components";
import axios from "axios";

import {
  APPLICATION_URL,
  DASHBOARD_BLUE,
  DASHBOARD_ORANGE,
  NJCountyCodes,
} from "../constants";

import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import ExpandArrowIcon from "@mui/icons-material/NorthEast";
import MinimizeArrowIcon from "@mui/icons-material/SouthWest";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

import PieChart from "../components/nj-macro/MacroOutputPieChart";
import CTBarChart from "../components/nj-macro/CensusTractBarChart";
import ResultsBarChart from "../components/nj-macro/ResultsBarChart";
import withAuth from "../components/4d-land/withAuth";

const HamburgerMenu = styled(MenuIcon)`
  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s;
  }
`;

const AreaSelectContainer = styled.div`
  .dropdownButton {
    color: ${DASHBOARD_ORANGE};
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
  }
`;

const AnalysisModalButton = styled(Button)`
  background-color: white;
  color: black;
  font-weight: bold;
  border: 1px solid black;

  &:hover {
    background-color: #d3d3d3;
  }

  i {
    padding-left: 5px;
  }
`;

let isFirstMount = true;

const NJMacro = () => {
  const [attributes, setAttributes] = useState([]);
  const [lsadNames, setLsadNames] = useState([]);
  const [countyNames, setCountyNames] = useState([]);
  const [selectedCountyNames, setSelectedCountyNames] = useState([]);
  const [selectedNameLSADs, setSelectedNameLSADs] = useState([]);
  const [
    selectedState,
    // setSelectedState
  ] = useState(34);
  const [isAllCountiesSelected, setIsAllCountiesSelected] = useState(false);
  const [isAllLSADsSelected, setIsAllLSADsSelected] = useState(false);
  const [historicalFloodDamageChecked, setHistoricalFloodDamageChecked] =
    useState(false);
  const [futureFloodRiskChecked, setFutureFloodRiskChecked] = useState(false);
  const [
    historicalGrantAllocationsChecked,
    setHistoricalGrantAllocationsChecked,
  ] = useState(false);
  const [
    socioeconomicVulnerabilitiesChecked,
    setSocioeconomicVulnerabilitiesChecked,
  ] = useState(false);
  const [isCountyDropdownOpen, setIsCountyDropdownOpen] = useState(false);
  const [isNameLSADDropdownOpen, setIsNameLSADDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("data");
  const [numAnalysisAttributesSelected, setNumAnalysisAttributesSelected] =
    useState(0);
  const [selectingByRectangle, setSelectingByRectangle] = useState(false);
  const [normalizedWeightAttributes, setNormalizedWeightAttributes] =
    useState(true);
  const [sliderValues, setSliderValues] = useState({
    setting1: 0,
    setting2: 0,
    setting3: 0,
    setting4: 0,
  });
  const [sliderValueSum, setSliderValueSum] = useState(0);
  const [selectedAreaOption, setSelectedAreaOption] = useState("option1");
  const [searchQuery, setSearchQuery] = useState("");
  const [lsadSearchQuery, setLsadSearchQuery] = useState("");
  const searchInputRef = useRef(null);
  const lsadInputRef = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mapSelectionData, setMapSelectionData] = useState([]);
  const [showAnalysisPanel, setShowAnalysisPanel] = useState("none");
  const [pendingDataChange, setPendingDataChange] = useState(false);
  const [fullscreenAnalysisPanel, setFullscreenAnalysisPanel] = useState(false);
  const [openModalAnalysisTypes, setOpenModalAnalysisTypes] = useState(["4"]);
  const [macroAnalysisData, setMacroAnalysisData] = useState([]);
  const [futureFloodRiskData, setFutureFloodRiskData] = useState([]);
  const [socioeconomicData, setSocioeconomicData] = useState([]);
  const [historicalClaimsData, setHistoricalClaimsData] = useState([]);
  const [historicalGrantAllocationsData, setHistoricalGrantAllocationsData] =
    useState([]);
  const [mapPrinting, setMapPrinting] = useState(false);
  const [htmlDownloading, setHTMLDownloading] = useState(false);
  const [resultsTableDisplay, setResultsTableDisplay] = useState("weighted");
  const [resultsTableExpanded, setResultsTableExpanded] = useState(false);
  const [socioeconomicSum, setSocioeconomicSum] = useState(0);
  const rightSideChartRef = useRef(null);

  const [gradientColors, setGradientColors] = useState([]);

  const handleGradientColorChange = (colors) => {
    setGradientColors(colors);
  };

  const [
    activeFutureFloodRiskCarouselIndex,
    setActiveFutureFloodRiskCarouselIndex,
  ] = useState(0);
  const [activeFloodDamageCarouselIndex, setActiveFloodDamageCarouselIndex] =
    useState(0);
  const [
    activeSocioeconomoicCarouselIndex,
    setActiveSocioeconomicCarouselIndex,
  ] = useState(0);
  const [
    activeGrantAllocationsCarouselIndex,
    setActiveGrantAllocationsCarouselIndex,
  ] = useState(0);

  const [
    currentFutureFloodRiskDropdownCounty,
    setCurrentFutureFloodRiskDropdownCounty,
  ] = useState("");
  const [
    currentFloodDamageDropdownCounty,
    setCurrentFloodDamageDropdownCounty,
  ] = useState("");
  const [
    currentSocioeconomicDropdownCounty,
    setCurrentSocioeconomicDropdownCounty,
  ] = useState("");
  const [
    currentGrantAllocationDropdownCounty,
    setCurrentGrantAllocationDropdownCounty,
  ] = useState("");

  const [updatedSocioeconomicData, setUpdatedSocioeconomicData] = useState({});
  const [updatedHistoricalClaimsData, setUpdatedHistoricalClaimsData] =
    useState({});
  const [
    updatedHistoricalGrantAllocationsData,
    setUpdatedHistoricalGrantAllocationsData,
  ] = useState({});

  const [weightedSums, setWeightedSums] = useState({});

  const orderedSelectedCountyNames =
    Object.values(macroAnalysisData).length &&
    JSON.parse(macroAnalysisData[0]).map((county) => county.countyName);

  let historicalClaimSum = historicalClaimsData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.policyCountSum;
    },
    0
  );

  let historicalGrantSum = historicalGrantAllocationsData.reduce(
    (accumulator, currentValue) => {
      return accumulator + (currentValue.propertiesCount || 0);
    },
    0
  );

  let sumOfWeightedSums =
    weightedSums &&
    Object.values(weightedSums).length &&
    Object.values(weightedSums).reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  const downloadRightSideChartHTML = async () => {
    if (!rightSideChartRef.current) {
      console.error("Ref not properly assigned");
      return;
    }

    const clonedContainer = rightSideChartRef.current.cloneNode(true);

    const futureFloodRiskArrows = clonedContainer.querySelector(
      "#future-flood-risk-arrows"
    );
    const historicalClaimsArrows = clonedContainer.querySelector(
      "#historical-claims-arrows"
    );
    const grantAllocationsArrows = clonedContainer.querySelector(
      "#grant-allocation-arrows"
    );
    const socioeconomicArrows = clonedContainer.querySelector(
      "#socioeconomic-arrows"
    );
    const previousNext = clonedContainer.querySelectorAll(".visually-hidden");

    if (futureFloodRiskArrows) {
      futureFloodRiskArrows.remove();
    }
    if (historicalClaimsArrows) {
      historicalClaimsArrows.remove();
    }
    if (grantAllocationsArrows) {
      grantAllocationsArrows.remove();
    }
    if (socioeconomicArrows) {
      socioeconomicArrows.remove();
    }
    previousNext.forEach((node) => {
      node.remove();
    });

    const styles = `
    <style>
      body {
        display: flex;
        justify-content: center;
      }
    </style>
  `;

    const styledHTML = `
    <html>
      <head>
      <meta charset="UTF-8">
        ${styles}
      </head>
      <body>
        ${clonedContainer.outerHTML}
      </body>
    </html>
  `;

    const blob = new Blob([styledHTML], { type: "text/html" });
    const reader = new FileReader();

    reader.onload = function (event) {
      const htmlContent = event.target.result;

      const parser = new DOMParser();

      const parsedHTML = parser.parseFromString(htmlContent, "text/html");

      const firstLineDiv = document.createElement("h1");
      firstLineDiv.innerHTML = `Risk Assessment Analysis for ${
        selectedAreaOption === "option1" ? "Counties" : "Census Tracts"
      }`;
      firstLineDiv.style.textAlign = "center";
      firstLineDiv.style.marginBottom = "20px";
      const rightSideChartsDiv = parsedHTML.querySelector("#right-side-charts");

      rightSideChartsDiv.insertBefore(
        firstLineDiv,
        rightSideChartsDiv.firstElementChild
      );

      const buttons = parsedHTML.querySelectorAll("button");
      buttons.forEach((button) => {
        let textContent = "";
        const buttonChild = button.querySelector("div");

        if (buttonChild) {
          const textParent = buttonChild.querySelector("div");
          textContent = textParent.textContent;
        }

        const textNode = document.createTextNode(textContent);
        button.parentNode.replaceChild(textNode, button);
      });

      const modifiedHTML = new XMLSerializer().serializeToString(parsedHTML);

      const modifiedBlob = new Blob([modifiedHTML], { type: "text/html" });

      const url = URL.createObjectURL(modifiedBlob);

      window.open(url, "_blank");
    };

    reader.readAsText(blob);
  };

  const handleAccordionToggle = (eventKey) => {
    if (eventKey.length === 5) {
      setOpenModalAnalysisTypes([]);
      return;
    }

    if (eventKey.length < 1) {
      setOpenModalAnalysisTypes(["0", "1", "2", "3", "4"]);
      return;
    }

    const isOpen = openModalAnalysisTypes.includes(eventKey);
    const newOpenModalAnalysisTypes =
      openModalAnalysisTypes.length && isOpen
        ? openModalAnalysisTypes.filter((key) => key !== eventKey)
        : [...openModalAnalysisTypes, eventKey];

    setOpenModalAnalysisTypes(newOpenModalAnalysisTypes);
  };

  const handleMenuToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const filteredCounties = useMemo(() => {
    return countyNames.filter((name) =>
      name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [countyNames, searchQuery]);

  const relevantAttributesForCounty = useMemo(() => {
    return attributes.filter((attr) =>
      selectedCountyNames.includes(attr.COUNTYNAME)
    );
  }, [attributes, selectedCountyNames]);

  const filteredTracts = useMemo(() => {
    return lsadNames.filter((name) => {
      const isCountySelected = relevantAttributesForCounty.some(
        (attr) => attr.CT_Name === name
      );
      if (!isCountySelected) return false;
      const numberMatch = name.match(/\d+/);
      const numberString = numberMatch ? numberMatch[0] : "";
      return numberString.includes(lsadSearchQuery);
    });
  }, [lsadNames, lsadSearchQuery, relevantAttributesForCounty]);

  const handleNamesRetrieved = useCallback(
    (retrievedAttributes) => {
      if (retrievedAttributes) {
        const plainObjectsArray = retrievedAttributes.map((attr) =>
          Object.assign({}, attr)
        );

        setAttributes(plainObjectsArray);
        const { uniqueCountyNames, uniqueLsadNames } = plainObjectsArray.reduce(
          (acc, attr) => {
            if (attr.CountyName2 !== null && attr.CountyName2 !== undefined) {
              acc.uniqueCountyNames.add(attr.CountyName2);
            }
            if (attr.CT_Name !== null && attr.CT_Name !== undefined) {
              acc.uniqueLsadNames.add(attr.CT_Name);
            }
            return acc;
          },
          { uniqueCountyNames: new Set(), uniqueLsadNames: new Set() }
        );

        setCountyNames([...uniqueCountyNames]);
        setLsadNames([...uniqueLsadNames]);
      }
    },
    [setAttributes, setCountyNames, setLsadNames]
  );

  const changeCountiesByRectangle = (countyList) => {
    setSelectedCountyNames(Array.from(countyList));
  };

  const changeCensusTractsByRectangle = (censusTracts) => {
    setSelectedNameLSADs(Array.from(censusTracts));
  };

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const invalidUnnormalizedValue =
    !normalizedWeightAttributes && sliderValueSum > 1;

  useEffect(() => {
    if (selectedAreaOption === "option1" && socioeconomicData.length) {
      setSocioeconomicSum(
        socioeconomicData.reduce((accumulator, currentValue) => {
          return (
            accumulator +
            (currentValue.disadvantagedCount.Disadvantaged +
              currentValue.disadvantagedCount["Not Disadvantaged"])
          );
        }, 0)
      );
    } else {
      setSocioeconomicSum(0);
    }
  }, [socioeconomicData]);

  useEffect(() => {
    const weightedSumsObj = {};

    if (
      selectedAreaOption === "option1" &&
      resultsTableDisplay === "weighted"
    ) {
      historicalClaimsData.forEach((county, index) => {
        const weightedSum =
          0 +
          (Number(county.policyCountSum) / Number(historicalClaimSum)) *
            Number(sliderValues["setting2"]) +
          (Number(historicalGrantAllocationsData[index].propertiesCount) /
            Number(historicalGrantSum)) *
            Number(sliderValues["setting3"]) +
          (Number(
            socioeconomicData[index].disadvantagedCount["Disadvantaged"] +
              socioeconomicData[index].disadvantagedCount["Not Disadvantaged"]
          ) /
            Number(socioeconomicSum)) *
            Number(sliderValues["setting4"]);

        weightedSumsObj[NJCountyCodes[county.code]] = weightedSum;
      });
    }

    if (
      selectedAreaOption === "option1" &&
      resultsTableDisplay === "original"
    ) {
      historicalClaimsData.forEach((county, index) => {
        const sum =
          0 +
          Number(county.policyCountSum) +
          Number(historicalGrantAllocationsData[index].propertiesCount) +
          Number(
            socioeconomicData[index].disadvantagedCount["Disadvantaged"] +
              socioeconomicData[index].disadvantagedCount["Not Disadvantaged"]
          );

        weightedSumsObj[NJCountyCodes[county.code]] = sum;
      });
    }

    if (!Object.values(weightedSumsObj).includes(Infinity)) {
      setWeightedSums(weightedSumsObj);
    }
  }, [
    futureFloodRiskData,
    historicalClaimsData,
    historicalGrantAllocationsData,
    socioeconomicData,
    resultsTableDisplay,
  ]);

  useEffect(() => {
    let mappedData;
    if (selectedAreaOption === "option1") {
      mappedData = Object.keys(socioeconomicData).map((countyCode) => ({
        code: socioeconomicData[countyCode].code,
        name: socioeconomicData[countyCode].name,
        floodRisk: 0,
      }));
    } else {
      mappedData = Object.keys(historicalClaimsData).map((code) => ({
        code: historicalClaimsData[code].code,
        floodRisk: 0,
      }));
    }

    setFutureFloodRiskData(mappedData);
  }, [socioeconomicData]);

  useEffect(() => {
    if (updatedSocioeconomicData) {
      setSocioeconomicData(Object.values(updatedSocioeconomicData));
    }
  }, [updatedSocioeconomicData]);

  useEffect(() => {
    if (historicalClaimsData) {
      setHistoricalClaimsData(Object.values(updatedHistoricalClaimsData));
    }
  }, [updatedHistoricalClaimsData]);

  useEffect(() => {
    if (historicalGrantAllocationsData) {
      setHistoricalGrantAllocationsData(
        Object.values(updatedHistoricalGrantAllocationsData)
      );
    }
  }, [updatedHistoricalGrantAllocationsData]);

  useEffect(() => {
    if (isFirstMount && Object.values(macroAnalysisData).length) {
      setShowAnalysisPanel("expanded");
      isFirstMount = false;
    } else {
      if (Object.values(macroAnalysisData).length) {
        setPendingDataChange(true);
        if (showAnalysisPanel === "none") {
          setShowAnalysisPanel("expanded");
        }
      }
    }
  }, [macroAnalysisData]);

  useEffect(() => {
    if (showAnalysisPanel === "expanded") {
      setPendingDataChange(false);
    }
  }, [showAnalysisPanel]);

  useEffect(() => {
    if (normalizedWeightAttributes) {
      for (const key in sliderValues) {
        if (sliderValues[key] !== 0) {
          setSliderValues((prevSliderValues) => ({
            ...prevSliderValues,
            [key]: sliderValues[key] / sliderValueSum,
          }));
        }
      }
    }
  }, [normalizedWeightAttributes]);

  useEffect(() => {
    setSliderValues({
      setting1: futureFloodRiskChecked ? 1 / numAnalysisAttributesSelected : 0,
      setting2: historicalFloodDamageChecked
        ? 1 / numAnalysisAttributesSelected
        : 0,
      setting3: historicalGrantAllocationsChecked
        ? 1 / numAnalysisAttributesSelected
        : 0,
      setting4: socioeconomicVulnerabilitiesChecked
        ? 1 / numAnalysisAttributesSelected
        : 0,
    });
  }, [numAnalysisAttributesSelected]);

  useEffect(() => {
    setSliderValueSum(
      Object.values(sliderValues).reduce((sum, value) => sum + value, 0)
    );
  }, [sliderValues]);

  useEffect(() => {
    if (sliderValueSum !== 1.0) {
      setNormalizedWeightAttributes(false);
    }
  }, [sliderValueSum]);

  const handleCheckboxChange = useCallback((checkboxName, isChecked) => {
    setNumAnalysisAttributesSelected((prevCount) =>
      isChecked ? prevCount + 1 : prevCount - 1
    );
    switch (checkboxName) {
      case "historicalFloodDamageChecked":
        setHistoricalFloodDamageChecked(isChecked);
        break;
      case "futureFloodRiskChecked":
        setFutureFloodRiskChecked(isChecked);
        break;
      case "historicalGrantAllocationsChecked":
        setHistoricalGrantAllocationsChecked(isChecked);
        break;
      case "socioeconomicVulnerabilitiesChecked":
        setSocioeconomicVulnerabilitiesChecked(isChecked);
        break;
      default:
        break;
    }
  }, []);

  const isAnyCheckboxChecked = () => {
    return (
      historicalFloodDamageChecked ||
      futureFloodRiskChecked ||
      historicalGrantAllocationsChecked ||
      socioeconomicVulnerabilitiesChecked
    );
  };

  const handleRadioClick = (e) => {
    setSelectedAreaOption(e.target.value);
  };

  const handleSliderChange = (key, value) => {
    setSliderValues((prevSliderValues) => ({
      ...prevSliderValues,
      [key]: value,
    }));
  };

  const handleLSADCheckboxChange = (name, isChecked) => {
    if (isChecked) {
      setSelectedNameLSADs((prevNames) => [...prevNames, name]);
    } else {
      setSelectedNameLSADs((prevNames) => prevNames.filter((n) => n !== name));
    }
  };

  const handleSelectAllLSADChange = (isChecked) => {
    if (isChecked) {
      setSelectedNameLSADs(filteredTracts);
    } else {
      setSelectedNameLSADs([]);
    }
  };

  const handleNameLSADChange = (e, nameLSAD) => {
    if (e.target.checked) {
      setSelectedNameLSADs((prevNames) => [...prevNames, nameLSAD]);
    } else {
      setSelectedNameLSADs((prevNames) =>
        prevNames.filter((n) => n !== nameLSAD)
      );
    }
  };

  const handleCountyCheckboxChange = (name, isChecked) => {
    if (isChecked) {
      setSelectedCountyNames((prevCounties) => [...prevCounties, name]);
    } else {
      setSelectedCountyNames((prevCounties) =>
        prevCounties.filter((n) => n !== name)
      );
    }
  };

  const handleSelectAllCountyChange = (isChecked) => {
    if (isChecked) {
      setSelectedCountyNames(countyNames);
    } else {
      setSelectedCountyNames([]);
    }
  };

  const handleCountyChange = (e, county) => {
    if (e.target.checked) {
      setSelectedCountyNames((prevCounties) => [...prevCounties, county]);
    } else {
      setSelectedCountyNames((prevCounties) =>
        prevCounties.filter((n) => n !== county)
      );
    }
  };

  const tableTooltip = (data, type, selectionMode) => {
    return (
      <Tooltip style={{ width: 400, background: "none" }}>
        <div style={{ width: 400 }}>
          <PieChart data={data} type={type} selectionMode={selectionMode} />
        </div>
      </Tooltip>
    );
  };

  const calculateAndFetchResults = () => {
    let organizedTractCEs = {};
    const relevantFipsCodes = attributes
      .filter((attr) => selectedCountyNames.includes(attr.COUNTYNAME))
      .map((attr) => attr.FIPS_COU);
    const relevantFipsCTCodes = attributes
      .filter((attr) => selectedNameLSADs.includes(attr.CT_Name))
      .map((attr) => attr.FIPS_CT);

    if (selectedAreaOption === "option2") {
      relevantFipsCodes.forEach((code) => {
        organizedTractCEs[code] = [];

        const matchingFipsCTs = attributes
          .filter(
            (attr) =>
              attr.FIPS_COU === code &&
              relevantFipsCTCodes.includes(attr.FIPS_CT)
          )
          .map((attr) => attr.FIPS_CT);

        organizedTractCEs[code] = matchingFipsCTs;
      });
    } else if (selectedAreaOption === "option1") {
      relevantFipsCodes.forEach((code) => {
        organizedTractCEs[code] = [];

        // Filter the attributes for matching FIPS_COU and pick all of its FIPS_CT
        const allFipsCTsForCounty = attributes
          .filter((attr) => attr.FIPS_COU === code)
          .map((attr) => attr.FIPS_CT);

        organizedTractCEs[code] = allFipsCTsForCounty;
      });
    }

    const payload = {
      stateName: selectedState,
      countyTracts: organizedTractCEs,
      sliderValues: sliderValues,
      selectAreaBy: selectedAreaOption,
    };

    // Logging the payload
    console.log("Sending the following payload to the server:", payload);

    // fetch("/calculate_button", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(payload),
    // })
    //   .then((response) => {
    //     console.log(response);
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     const filePath = data.filePath;
    //     if (filePath) {
    //       window.open(filePath, "_blank");
    //       const downloadLink = document.createElement("a");
    //       downloadLink.href = filePath;
    //       downloadLink.download = "YourDesiredFileName.pdf";
    //       document.body.appendChild(downloadLink);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data: ", error);
    //   });
  };

  const noAnalysisTypeSelected =
    !historicalFloodDamageChecked &&
    !historicalGrantAllocationsChecked &&
    !futureFloodRiskChecked &&
    !socioeconomicVulnerabilitiesChecked;

  const validSelectionWithType =
    (selectedAreaOption === "option1" && selectedCountyNames.length) ||
    (selectedAreaOption === "option2" && selectedNameLSADs.length);

  const getSelectionInformation = async (data, selectedAreaOption) => {
    if (validSelectionWithType)
      try {
        const response = await axios.get(
          `${APPLICATION_URL}/api/getMacroSelectionInfo`,
          {
            params: {
              data: JSON.stringify(data),
              selectionMode:
                selectedAreaOption === "option1" ? "county" : "censusTract",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          setMacroAnalysisData(response.data);

          const tempSocioeconomicData = response.data && [
            ...Object.values(JSON.parse(response.data[0])),
          ];

          const tempHistoricalClaimsData = response.data && [
            ...Object.values(JSON.parse(response.data[1])),
          ];
          let tempHistoricalGrantAllocationsData;

          if (selectedAreaOption === "option1") {
            tempHistoricalGrantAllocationsData = response.data && [
              ...Object.values(JSON.parse(response.data[2])),
            ];

            if (tempSocioeconomicData) {
              const modifiedSocioeconomicData = tempSocioeconomicData
                .map((geoId) => {
                  geoId.code = geoId.countyCode;
                  geoId.name = geoId.countyName;
                  return geoId;
                })
                .map((geoId) => {
                  delete geoId.countyCode;
                  delete geoId.countyName;
                  return geoId;
                });
              setUpdatedSocioeconomicData(modifiedSocioeconomicData);
            }

            if (tempHistoricalClaimsData) {
              const modifiedHistoricalClaimsData = tempHistoricalClaimsData.map(
                (geoId) => {
                  const modifiedGeoId = {};
                  Object.keys(geoId).forEach((key) => {
                    switch (key) {
                      case "countyCode":
                        modifiedGeoId.code = geoId.countyCode;
                        break;
                      case "countyPolicyCountSum":
                        modifiedGeoId.policyCountSum =
                          geoId.countyPolicyCountSum;
                        break;
                      case "countyPolicyCountSumYearly":
                        modifiedGeoId.policyCountSumYearly =
                          geoId.countyPolicyCountSumYearly;
                        break;
                      default:
                        modifiedGeoId[key] = geoId[key];
                        break;
                    }
                  });
                  return modifiedGeoId;
                }
              );
              setUpdatedHistoricalClaimsData(modifiedHistoricalClaimsData);
            }

            if (tempHistoricalGrantAllocationsData) {
              const modifiedHistoricalGrantAllocationsData =
                tempHistoricalGrantAllocationsData.map((geoId) => {
                  const modifiedGeoId = {};
                  Object.keys(geoId).forEach((key) => {
                    switch (key) {
                      case "countyCode":
                        modifiedGeoId.code = geoId.countyCode;
                        break;
                      case "countyAmountSum":
                        modifiedGeoId.amountSum = geoId.countyAmountSum;
                        break;
                      case "countyAmountSumYearly":
                        modifiedGeoId.amountSumYearly =
                          geoId.countyAmountSumYearly;
                        break;
                      case "countyName":
                        modifiedGeoId.name = geoId.countyName;
                        break;
                      case "countyPropertiesCount":
                        modifiedGeoId.propertiesCount =
                          geoId.countyPropertiesCount;
                        break;
                      case "countyPropertiesCountYearly":
                        modifiedGeoId.propertiesCountYearly =
                          geoId.countyPropertiesCountYearly;
                        break;
                      default:
                        modifiedGeoId[key] = geoId[key];
                        break;
                    }
                  });
                  return modifiedGeoId;
                });
              setUpdatedHistoricalGrantAllocationsData(
                modifiedHistoricalGrantAllocationsData
              );
            }
          }

          if (selectedAreaOption === "option2") {
            if (tempHistoricalClaimsData) {
              const modifiedHistoricalClaimsData = tempHistoricalClaimsData.map(
                (geoId) => {
                  const modifiedGeoId = {};
                  Object.keys(geoId).forEach((key) => {
                    switch (key) {
                      case "censusTract":
                        modifiedGeoId.code = geoId.censusTract;
                        break;
                      case "countyPolicyCountSum":
                        modifiedGeoId.policyCountSum =
                          geoId.tractPolicyCountSum;
                        break;
                      case "tractPolicyCountSumYearly":
                        modifiedGeoId.policyCountSumYearly =
                          geoId.tractPolicyCountSumYearly;
                        break;
                      default:
                        modifiedGeoId[key] = geoId[key];
                        break;
                    }
                  });
                  return modifiedGeoId;
                }
              );
              setUpdatedHistoricalClaimsData(modifiedHistoricalClaimsData);
            }

            if (tempSocioeconomicData) {
              setUpdatedSocioeconomicData(tempSocioeconomicData);
            }
          }
        } else {
          throw new Error("Error sending selection information");
        }
      } catch (error) {
        console.log(error);
        console.error("Error sending data to the backend:", error.message);
      }
  };

  return (
    <div className="macro-tool-widget">
      {/* Tool Side */}
      <div className="widget-tool-side">
        {/* Top Nav and Title */}
        <div className="tool-title">
          <HamburgerMenu
            style={{ marginRight: 10, fontSize: 40 }}
            onClick={() => handleMenuToggle()}
          />
          <Dropdown show={dropdownOpen} onToggle={handleMenuToggle}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              style={{
                backgroundColor: "transparent",
                border: "none",
                position: "absolute",
              }}
            />

            <Dropdown.Menu>
              <Dropdown.Item href="/" onClick={handleMenuToggle}>
                Home
              </Dropdown.Item>
              <Dropdown.Item
                href="/crs-dashboard"
                onClick={handleMenuToggle}
                style={{ whiteSpace: "normal" }}
              >
                CRS Assessment Tool
              </Dropdown.Item>
              <Dropdown.Item
                href="/nj-macro"
                onClick={handleMenuToggle}
                style={{ whiteSpace: "normal" }}
              >
                New Jersey Macro Tool
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <h1>NJ Macro Tool</h1>
        </div>

        <div className="button-holder">
          <div className="tab-group">
            <div
              className={`tab${activeTab === "data" ? " active" : ""}`}
              onClick={() => toggleTab("data")}
            >
              Data
            </div>
            <div
              className={`tab${activeTab === "settings" ? " active" : ""}`}
              onClick={() => toggleTab("settings")}
            >
              Settings
            </div>
            <div
              className={`tab${activeTab === "output" ? " active" : ""}`}
              onClick={() => toggleTab("output")}
              style={{
                pointerEvents: invalidUnnormalizedValue ? "none" : "auto",
                opacity: invalidUnnormalizedValue ? 0.5 : 1,
              }}
            >
              Output
            </div>
          </div>
        </div>

        {/* Inputs of left holder */}
        <div className="input-holder">
          {/* Data Tab */}
          <div
            className={`tab-content${activeTab === "data" ? " active" : ""}`}
            id="data"
          >
            <div className="orange-container" id="StateSelector">
              <div className="container-topper">
                <div className="dataTitleTopper">Select State</div>
                <div className="select-wrapper">
                  <select className="stateDropdown">
                    <option value="option1" defaultValue>
                      New Jersey
                    </option>
                  </select>
                  <div className="select-arrow">&#9660;</div>
                </div>
              </div>
            </div>
            <div className="orange-container" id="AreaSelector">
              <div className="container-topper">
                <div className="dataTitleTopper">Select Area By</div>
                <div className="test">
                  <div className="radioSelected">
                    <input
                      type="radio"
                      name="county"
                      value="option1"
                      checked={selectedAreaOption === "option1"}
                      onClick={handleRadioClick}
                      className="radioInput"
                    />
                    <div style={{ marginLeft: 5 }}>County</div>
                  </div>
                  <div className="radioSelected">
                    <input
                      type="radio"
                      name="censustract"
                      value="option2"
                      checked={selectedAreaOption === "option2"}
                      onClick={handleRadioClick}
                      className="radioInput"
                    />
                    <div style={{ marginLeft: 5 }}>Census Tract</div>
                  </div>
                </div>
              </div>
              <AreaSelectContainer className="container-inner">
                {selectedAreaOption === "option1" ? (
                  <div>
                    <form className="row">
                      <div
                        className="btn-group"
                        onMouseLeave={() => {
                          if (
                            !searchInputRef.current.contains(
                              document.activeElement
                            )
                          ) {
                            setIsCountyDropdownOpen(false);
                          }
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-sm dropdownButton"
                          disabled={selectingByRectangle ? true : false}
                        >
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="County(s)"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onFocus={() => setIsCountyDropdownOpen(true)}
                            onMouseLeave={() => {
                              if (
                                !searchInputRef.current.contains(
                                  document.activeElement
                                )
                              ) {
                                setIsCountyDropdownOpen(false);
                              }
                            }}
                            ref={searchInputRef}
                          />
                        </button>
                        <button
                          type="button"
                          className="btn dropdownButton btn-sm dropdown-toggle dropdown-toggle-split"
                          onClick={() =>
                            setIsCountyDropdownOpen(!isCountyDropdownOpen)
                          }
                          style={{ fontSize: 30 }}
                          disabled={selectingByRectangle ? true : false}
                        >
                          <span className="visually-hidden">
                            Toggle Dropdown
                          </span>
                        </button>
                        <div
                          className="analysis-type-checkbox"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "40%",
                            marginLeft: "2%",
                            textAlign: "left",
                          }}
                        >
                          <CheckboxWithPopout
                            isChecked={selectingByRectangle}
                            onChange={() => {
                              setSelectingByRectangle(!selectingByRectangle);
                            }}
                          />
                          <label style={{ marginLeft: 10 }}>
                            Select by drawing polygon on the map
                          </label>
                        </div>
                        {isCountyDropdownOpen && (
                          <ul
                            className="dropdown-menu show left"
                            style={{ maxHeight: "100px", overflowY: "auto" }}
                          >
                            <li className="dropdown-item">
                              <div>
                                <input
                                  type="checkbox"
                                  id="checkbox-select-all-countyname"
                                  checked={isAllCountiesSelected}
                                  onChange={(e) => {
                                    setIsAllCountiesSelected(e.target.checked);
                                    handleSelectAllCountyChange(
                                      e.target.checked
                                    );
                                  }}
                                />
                                <label
                                  htmlFor="checkbox-select-all-countyname"
                                  style={{ marginLeft: 5 }}
                                >
                                  Select All
                                </label>
                              </div>
                            </li>
                            {filteredCounties.map((name, index) => (
                              <li key={index} className="dropdown-item">
                                <div>
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${name}`}
                                    onChange={(e) =>
                                      handleCountyCheckboxChange(
                                        name,
                                        e.target.checked
                                      )
                                    }
                                    value={name}
                                    checked={selectedCountyNames.includes(name)}
                                  />
                                  <label
                                    htmlFor={`checkbox-${name}`}
                                    style={{ marginLeft: 5 }}
                                  >
                                    {name}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </form>
                    <div className="selected-name-lsads-container">
                      {selectedCountyNames.map((countyName, index) => (
                        <div key={index} className="selected-name-lsad">
                          {countyName}
                          <div
                            className="remove-name-lsad"
                            onClick={() =>
                              handleCountyChange(
                                { target: { checked: false } },
                                countyName
                              )
                            }
                          >
                            &#10005;
                          </div>
                          ,
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="gap1">
                      <div
                        className="btn-group"
                        onMouseLeave={() => {
                          if (
                            !searchInputRef.current.contains(
                              document.activeElement
                            )
                          ) {
                            setIsCountyDropdownOpen(false);
                          }
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-sm dropdownButton"
                        >
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="County(s)"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onFocus={() => {
                              setIsCountyDropdownOpen(true);
                              setIsNameLSADDropdownOpen(false);
                            }}
                            ref={searchInputRef}
                          />
                        </button>
                        <button
                          type="button"
                          className="btn dropdownButton btn-sm dropdown-toggle dropdown-toggle-split"
                          onClick={() =>
                            setIsCountyDropdownOpen(!isCountyDropdownOpen)
                          }
                          style={{ fontSize: 30 }}
                        >
                          <span className="visually-hidden">
                            Toggle Dropdown
                          </span>
                        </button>
                        {isCountyDropdownOpen && (
                          <ul
                            className="dropdown-menu show left"
                            style={{ maxHeight: "100px", overflowY: "auto" }}
                          >
                            <li className="dropdown-item">
                              <div>
                                <input
                                  type="checkbox"
                                  id="checkbox-select-all-countyname"
                                  checked={isAllCountiesSelected}
                                  onChange={(e) => {
                                    setIsAllCountiesSelected(e.target.checked);
                                    handleSelectAllCountyChange(
                                      e.target.checked
                                    );
                                  }}
                                />
                                <label
                                  htmlFor="checkbox-select-all-countyname"
                                  style={{ marginLeft: 5 }}
                                >
                                  Select All
                                </label>
                              </div>
                            </li>
                            {filteredCounties.map((name, index) => (
                              <li key={index} className="dropdown-item">
                                <div>
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${name}`}
                                    onChange={(e) =>
                                      handleCountyCheckboxChange(
                                        name,
                                        e.target.checked
                                      )
                                    }
                                    value={name}
                                    checked={selectedCountyNames.includes(name)}
                                  />
                                  <label
                                    htmlFor={`checkbox-${name}`}
                                    style={{ marginLeft: 5 }}
                                  >
                                    {name}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <div
                        className="btn-group"
                        onMouseLeave={() => {
                          if (
                            !lsadInputRef.current.contains(
                              document.activeElement
                            )
                          ) {
                            setIsNameLSADDropdownOpen(false);
                          }
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-sm dropdownButton"
                        >
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Census Tract(s)"
                            value={lsadSearchQuery}
                            onChange={(e) => setLsadSearchQuery(e.target.value)}
                            onFocus={() => {
                              setIsNameLSADDropdownOpen(true);
                              setIsCountyDropdownOpen(false);
                            }}
                            ref={lsadInputRef}
                          />
                        </button>
                        <button
                          type="button"
                          className="btn dropdownButton btn-sm dropdown-toggle dropdown-toggle-split"
                          onClick={() => {
                            setIsNameLSADDropdownOpen(!isNameLSADDropdownOpen);
                            setIsCountyDropdownOpen(false);
                          }}
                          style={{ fontSize: 30 }}
                        >
                          <span className="visually-hidden">
                            Toggle Dropdown
                          </span>
                        </button>
                        {isNameLSADDropdownOpen && (
                          <ul
                            className="dropdown-menu show right"
                            style={{ maxHeight: "100px", overflowY: "auto" }}
                          >
                            <li className="dropdown-item">
                              <div>
                                <input
                                  type="checkbox"
                                  id="checkbox-select-all-lsadname"
                                  checked={isAllLSADsSelected}
                                  onChange={(e) => {
                                    setIsAllLSADsSelected(e.target.checked);
                                    handleSelectAllLSADChange(e.target.checked);
                                  }}
                                />
                                <label
                                  htmlFor="checkbox-select-all-lsadname"
                                  style={{ marginLeft: 5 }}
                                >
                                  Select All
                                </label>
                              </div>
                            </li>
                            {filteredTracts.map((name, index) => (
                              <li key={index} className="dropdown-item">
                                <div>
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${name}`}
                                    onChange={(e) =>
                                      handleLSADCheckboxChange(
                                        name,
                                        e.target.checked
                                      )
                                    }
                                    value={name}
                                    checked={selectedNameLSADs.includes(name)}
                                  />
                                  <label
                                    htmlFor={`checkbox-${name}`}
                                    style={{ marginLeft: 5 }}
                                  >
                                    {name}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="selected-name-lsads-container">
                      {selectedNameLSADs.map((nameLSAD, index) => (
                        <div key={index} className="selected-name-lsad">
                          {nameLSAD}
                          <div
                            className="remove-name-lsad"
                            onClick={() =>
                              handleNameLSADChange(
                                { target: { checked: false } },
                                nameLSAD
                              )
                            }
                          >
                            &#10005;
                          </div>
                          ,
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </AreaSelectContainer>
            </div>
            <div className="orange-container" id="AnalysisSelector">
              <div className="container-topper">
                <div className="dataTitleTopper">Type of Analysis</div>
                <div className="dataTitleTopper">
                  <div
                    className="analysis-type-checkbox"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <CheckboxWithPopout
                      isChecked={
                        historicalFloodDamageChecked &&
                        futureFloodRiskChecked &&
                        historicalGrantAllocationsChecked &&
                        socioeconomicVulnerabilitiesChecked
                      }
                      onChange={() => {
                        if (
                          historicalFloodDamageChecked &&
                          futureFloodRiskChecked &&
                          historicalGrantAllocationsChecked &&
                          socioeconomicVulnerabilitiesChecked
                        ) {
                          setNumAnalysisAttributesSelected(0);
                          setHistoricalFloodDamageChecked(false);
                          setHistoricalGrantAllocationsChecked(false);
                          setFutureFloodRiskChecked(false);
                          setSocioeconomicVulnerabilitiesChecked(false);
                        } else {
                          setNumAnalysisAttributesSelected(4);
                          setHistoricalFloodDamageChecked(true);
                          setHistoricalGrantAllocationsChecked(true);
                          setFutureFloodRiskChecked(true);
                          setSocioeconomicVulnerabilitiesChecked(true);
                        }
                      }}
                    />
                    <label style={{ marginLeft: 5 }}>Select All</label>
                  </div>
                </div>
              </div>
              <div className="container-inner">
                <div className="analysis-type-checkbox">
                  <CheckboxWithPopout
                    isChecked={futureFloodRiskChecked}
                    onChange={(newState) =>
                      handleCheckboxChange("futureFloodRiskChecked", newState)
                    }
                  />
                  <label>Future Flood Risk</label>
                </div>
                <div className="analysis-type-checkbox">
                  <CheckboxWithPopout
                    isChecked={historicalFloodDamageChecked}
                    onChange={(newState) =>
                      handleCheckboxChange(
                        "historicalFloodDamageChecked",
                        newState
                      )
                    }
                  />
                  <label>Historical Flood Damages</label>
                </div>
                <div className="analysis-type-checkbox">
                  <CheckboxWithPopout
                    isChecked={historicalGrantAllocationsChecked}
                    onChange={(newState) =>
                      handleCheckboxChange(
                        "historicalGrantAllocationsChecked",
                        newState
                      )
                    }
                  />
                  <label>Historical Grant Allocations</label>
                </div>
                <div className="analysis-type-checkbox">
                  <CheckboxWithPopout
                    isChecked={socioeconomicVulnerabilitiesChecked}
                    onChange={(newState) =>
                      handleCheckboxChange(
                        "socioeconomicVulnerabilitiesChecked",
                        newState
                      )
                    }
                  />
                  <label>Socioeconomic Vulnerabilities</label>
                </div>
              </div>
            </div>
          </div>

          {/* Settings Tab */}
          <div
            className={`tab-content${
              activeTab === "settings" ? " active" : ""
            }`}
            id="settings"
          >
            <div className="setting-container">
              <div className="container-topper">Select Weights</div>
              <div className="container-inner">
                {isAnyCheckboxChecked() ? (
                  <div>
                    {futureFloodRiskChecked && (
                      <div className="slider-option">
                        <div>Future Flood Risk</div>
                        <SliderComponent
                          value={sliderValues["setting1"]}
                          onChange={(value) =>
                            handleSliderChange("setting1", parseFloat(value))
                          }
                        />
                      </div>
                    )}
                    {historicalFloodDamageChecked && (
                      <div className="slider-option">
                        <div className="slider-title">
                          Historical Flood Damages
                        </div>
                        <SliderComponent
                          value={sliderValues["setting2"]}
                          onChange={(value) =>
                            handleSliderChange("setting2", parseFloat(value))
                          }
                        />
                      </div>
                    )}
                    {historicalGrantAllocationsChecked && (
                      <div className="slider-option">
                        <div>Historical Grant Allocations</div>
                        <SliderComponent
                          value={sliderValues["setting3"]}
                          onChange={(value) =>
                            handleSliderChange("setting3", parseFloat(value))
                          }
                        />
                      </div>
                    )}
                    {socioeconomicVulnerabilitiesChecked && (
                      <div className="slider-option">
                        <div>Socioeconomic Vulnerabilities</div>
                        <SliderComponent
                          value={sliderValues["setting4"]}
                          onChange={(value) =>
                            handleSliderChange("setting4", parseFloat(value))
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    Please go to the Data Tab to select weighted options
                  </div>
                )}
              </div>
            </div>
            {isAnyCheckboxChecked() && (
              <>
                {invalidUnnormalizedValue && (
                  <div
                    className="weight-sum-box"
                    style={{
                      color: "black",
                      backgroundColor:
                        sliderValueSum > 1
                          ? "rgb(255,0,0,0.15)"
                          : "rgba(253, 148, 38, 0.15)",
                    }}
                  >
                    If you choose to process data without normalization, please
                    keep the total sum of weights under 1.
                  </div>
                )}
                <div
                  className="weight-sum-box"
                  style={{
                    backgroundColor:
                      sliderValueSum > 1
                        ? "rgb(255,0,0,0.15)"
                        : "rgba(253, 148, 38, 0.15)",
                  }}
                >
                  Sum of Weights: {Math.round(sliderValueSum * 100) / 100}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: "2%",
                    width: "60%",
                    marginLeft: "2%",
                    textAlign: "left",
                  }}
                >
                  <CheckboxWithPopout
                    isChecked={normalizedWeightAttributes}
                    onChange={() => {
                      setNormalizedWeightAttributes(
                        !normalizedWeightAttributes
                      );
                    }}
                  />
                  <label style={{ marginLeft: 10 }}>
                    Apply Data Normalization
                  </label>
                </div>
              </>
            )}
          </div>

          {/* Output Tab */}
          <div
            className={`tab-content${activeTab === "output" ? " active" : ""}`}
            id="output"
          >
            <div className="output-container">
              <div className="container-topper">Output Controls</div>
              <div className="container-inner">
                {!validSelectionWithType ? (
                  <div style={{ marginBottom: "1%" }}>
                    Please select one or more counties/census tracts to
                    calculate.
                  </div>
                ) : noAnalysisTypeSelected ? (
                  <div style={{ marginBottom: "1%" }}>
                    Please select one or more analysis types to calculate.
                  </div>
                ) : (
                  <></>
                )}
                <button
                  className="output-button"
                  onClick={() => {
                    calculateAndFetchResults();
                    getSelectionInformation(
                      mapSelectionData,
                      selectedAreaOption
                    );
                  }}
                  disabled={
                    noAnalysisTypeSelected || !validSelectionWithType
                      ? true
                      : false
                  }
                  style={{
                    opacity:
                      noAnalysisTypeSelected || !validSelectionWithType
                        ? 0.3
                        : 1,
                    cursor:
                      noAnalysisTypeSelected || !validSelectionWithType
                        ? "auto"
                        : "pointer",
                    marginTop: "5%",
                    marginBottom: "5%",
                  }}
                >
                  Process
                </button>
                {selectedAreaOption === "option1" &&
                Object.values(macroAnalysisData).length ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", width: "80%" }}>
                      <button
                        className="results-toggle-button"
                        style={{
                          borderTopLeftRadius: 10,
                          backgroundColor:
                            resultsTableDisplay === "weighted"
                              ? "rgba(253, 148, 38, 0.15)"
                              : "white",
                        }}
                        onClick={() => setResultsTableDisplay("weighted")}
                      >
                        Weighted Results
                      </button>
                      <button
                        className="results-toggle-button"
                        style={{
                          borderTopRightRadius: 10,
                          backgroundColor:
                            resultsTableDisplay === "original"
                              ? "rgba(253, 148, 38, 0.15)"
                              : "white",
                        }}
                        onClick={() => setResultsTableDisplay("original")}
                      >
                        Original Results
                      </button>
                    </div>
                    <ExpandArrowIcon
                      style={{
                        display: "flex",
                        margin: "0 auto",
                        alignItems: "center",
                        color: "white",
                        backgroundColor: "#264e73",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                      className="expand-table-button"
                      onClick={() =>
                        setResultsTableExpanded(!resultsTableExpanded)
                      }
                    />
                  </div>
                ) : null}
                {Object.values(macroAnalysisData).length &&
                selectedAreaOption === "option1" ? (
                  <>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>
                            {selectedAreaOption === "option2"
                              ? "Census Tract"
                              : "County"}
                          </th>
                          <th>Weighted Sum</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historicalClaimsData.map((county, index) => {
                          const isOrange = index % 2 === 0; // Alternating colors
                          return (
                            <tr
                              key={county.code}
                              style={{
                                backgroundColor: isOrange
                                  ? "rgba(253, 148, 38, 0.15)"
                                  : "white",
                              }}
                            >
                              <td
                                style={{
                                  backgroundColor: isOrange
                                    ? "rgba(253, 148, 38, 0.15)"
                                    : "white",
                                }}
                              >
                                {selectedAreaOption === "option1"
                                  ? NJCountyCodes[county.code]
                                  : county.code}
                              </td>
                              <td
                                style={{
                                  backgroundColor: isOrange
                                    ? "rgba(253, 148, 38, 0.15)"
                                    : "white",
                                }}
                              >
                                {Object.values(weightedSums).length &&
                                  weightedSums[NJCountyCodes[county.code]] &&
                                  weightedSums[
                                    NJCountyCodes[county.code]
                                  ].toFixed(3)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div
                      style={{
                        margin: "0 auto",
                        display: "flex",
                        paddingTop: 20,
                        width: "100%",
                        overflowX: "scroll",
                      }}
                    >
                      <ColorBar
                        colors={gradientColors}
                        weightedSums={weightedSums}
                      />
                    </div>
                  </>
                ) : null}
                <Modal
                  show={resultsTableExpanded}
                  onHide={() => setResultsTableExpanded(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Detailed Results Table</Modal.Title>
                  </Modal.Header>
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      width: "80%",
                      margin: "0 auto",
                      marginTop: "2%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>
                          {selectedAreaOption === "option2"
                            ? "Census Tract"
                            : "County"}
                        </th>
                        <th>Future Flood Risk</th>
                        <th>Historical Flood Damages</th>
                        <th>Historical Grant Allocations</th>
                        <th>Socioeconomic Vulnerabilities</th>
                        <th>Weighted Sum</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historicalClaimsData.map((county, index) => {
                        const isOrange = index % 2 === 0;
                        return (
                          <tr
                            key={county.code}
                            style={{
                              backgroundColor: isOrange
                                ? "rgba(253, 148, 38, 0.15)"
                                : "white",
                            }}
                          >
                            <td
                              style={{
                                backgroundColor: isOrange
                                  ? "rgba(253, 148, 38, 0.15)"
                                  : "white",
                              }}
                            >
                              {selectedAreaOption === "option1"
                                ? NJCountyCodes[county.code]
                                : county.code}
                            </td>
                            <td
                              style={{
                                backgroundColor: isOrange
                                  ? "rgba(253, 148, 38, 0.15)"
                                  : "white",
                              }}
                            >
                              {futureFloodRiskData.length &&
                              futureFloodRiskData[index]
                                ? futureFloodRiskData[index].floodRisk || 0
                                : null}
                            </td>
                            <td
                              style={{
                                backgroundColor: isOrange
                                  ? "rgba(253, 148, 38, 0.15)"
                                  : "white",
                              }}
                            >
                              <div onClick={(e) => e.stopPropagation()}>
                                {county.policyCountSum}
                              </div>
                            </td>
                            <td
                              style={{
                                backgroundColor: isOrange
                                  ? "rgba(253, 148, 38, 0.15)"
                                  : "white",
                              }}
                            >
                              {historicalGrantAllocationsData.length &&
                              historicalGrantAllocationsData[index]
                                ? historicalGrantAllocationsData[index]
                                    .amountSum || 0
                                : null}
                            </td>
                            <td
                              style={{
                                backgroundColor: isOrange
                                  ? "rgba(253, 148, 38, 0.15)"
                                  : "white",
                              }}
                            >
                              {socioeconomicData.length &&
                                selectedAreaOption === "option1" &&
                                socioeconomicData[index].disadvantagedCount &&
                                Object.values(
                                  socioeconomicData[index].disadvantagedCount
                                ).length &&
                                socioeconomicData[index].disadvantagedCount
                                  .Disadvantaged +
                                  socioeconomicData[index].disadvantagedCount[
                                    "Not Disadvantaged"
                                  ]}
                            </td>
                            <td
                              style={{
                                backgroundColor: isOrange
                                  ? "rgba(253, 148, 38, 0.15)"
                                  : "white",
                              }}
                            >
                              {Object.values(weightedSums).length &&
                                weightedSums[NJCountyCodes[county.code]] &&
                                weightedSums[
                                  NJCountyCodes[county.code]
                                ].toFixed(3)}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>Sum</td>
                        <td>0</td>
                        <td>{historicalClaimSum}</td>
                        <td>{historicalGrantSum}</td>
                        <td>{socioeconomicSum}</td>
                        <td>{sumOfWeightedSums}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Map Side */}
      <div className="widget-map-side">
        <div
          style={{
            position: "absolute",
            zIndex: 100,
            right: showAnalysisPanel === "expanded" ? 0 : "20%",
            bottom: 0,
            top: showAnalysisPanel === "minimized" ? "95%" : "auto",
            width:
              showAnalysisPanel === "expanded" && fullscreenAnalysisPanel
                ? "100%"
                : showAnalysisPanel === "expanded"
                ? "70%"
                : "50%",
            color: "white",
            backgroundColor: "white",
            height: showAnalysisPanel === "none" ? 0 : "100%",
            borderTopRightRadius: showAnalysisPanel === "expanded" ? 0 : 10,
            display: "flex",
            overflowY: "scroll",
          }}
        >
          {showAnalysisPanel !== "none" && (
            <div
              style={{
                display: "flex",
                width: "100%",
                margin: "0 auto",
                alignItems: "center",
                height: 40,
                backgroundColor: pendingDataChange ? "#B4D3B2" : DASHBOARD_BLUE,
                borderTopRightRadius: showAnalysisPanel === "expanded" ? 0 : 10,
              }}
            >
              <div
                style={{
                  width: "90%",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  Output Analysis for Selected{" "}
                  {selectedAreaOption === "option1"
                    ? "Counties"
                    : "Census Tracts"}
                  &nbsp;
                  {pendingDataChange && "(Updated data charts available)"}
                </div>
                <div>
                  {showAnalysisPanel === "minimized" ? (
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        !Object.values(macroAnalysisData).length ? (
                          <Tooltip>
                            <div>
                              Please process macro analysis data before viewing
                              charts.
                            </div>
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <ExpandArrowIcon
                        style={{
                          color: "black",
                          backgroundColor: "white",
                          borderRadius: 5,
                          opacity: !Object.values(macroAnalysisData).length
                            ? 0.5
                            : 1,
                        }}
                        className="modal-control-icon"
                        onClick={() =>
                          !Object.values(macroAnalysisData).length
                            ? null
                            : setShowAnalysisPanel("expanded")
                        }
                      />
                    </OverlayTrigger>
                  ) : (
                    <MinimizeArrowIcon
                      style={{
                        color: "black",
                        backgroundColor: "white",
                        borderRadius: 5,
                      }}
                      className="modal-control-icon"
                      onClick={() => setShowAnalysisPanel("minimized")}
                    />
                  )}
                  {showAnalysisPanel === "expanded" &&
                  !fullscreenAnalysisPanel ? (
                    <FullscreenIcon
                      style={{
                        color: "black",
                        backgroundColor: "white",
                        borderRadius: 5,
                      }}
                      className="modal-control-icon"
                      onClick={() => setFullscreenAnalysisPanel(true)}
                    />
                  ) : showAnalysisPanel === "expanded" ? (
                    <CloseFullscreenIcon
                      style={{
                        color: "black",
                        backgroundColor: "white",
                        borderRadius: 5,
                      }}
                      className="modal-control-icon"
                      onClick={() => setFullscreenAnalysisPanel(false)}
                    />
                  ) : null}
                  <CloseIcon
                    className="modal-control-icon"
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      borderRadius: 5,
                    }}
                    onClick={() => setShowAnalysisPanel("none")}
                  ></CloseIcon>
                </div>
              </div>
            </div>
          )}
          {showAnalysisPanel === "expanded" && (
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: 40,
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft: "5%",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                    paddingTop: 10,
                  }}
                >
                  <AnalysisModalButton
                    onClick={() => downloadRightSideChartHTML()}
                  >
                    Open in New Tab
                    <i>
                      <PrintIcon style={{ color: DASHBOARD_ORANGE }} />
                    </i>
                  </AnalysisModalButton>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "5%",
                  }}
                >
                  <span
                    className="expand-modal"
                    onClick={() =>
                      openModalAnalysisTypes.length > 0
                        ? handleAccordionToggle(["0", "1", "2", "3", "4"])
                        : handleAccordionToggle([])
                    }
                  >
                    {openModalAnalysisTypes.length > 0
                      ? "Collapse All"
                      : "Expand All"}
                  </span>
                </div>
                <Accordion
                  activeKey={openModalAnalysisTypes}
                  ref={rightSideChartRef}
                  id="right-side-charts"
                >
                  {selectedAreaOption === "option1" && (
                    <Accordion.Item
                      eventKey="4"
                      onClick={() => handleAccordionToggle("4")}
                      className="analysis-accordion"
                    >
                      <Accordion.Header>
                        <div
                          style={{
                            display: "flex",
                            width: "95%",
                            justifyContent: "space-between",
                          }}
                          className="accordion-title"
                        >
                          <div>Summary</div>
                          <div>
                            {openModalAnalysisTypes.includes("4")
                              ? "Collapse"
                              : "Expand"}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body
                        style={{
                          display: "flex",
                          margin: "0 auto",
                          justifyContent: "center",
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ResultsBarChart
                          futureFloodRiskData={futureFloodRiskData}
                          historicalClaimsData={historicalClaimsData}
                          historicalGrantAllocationsData={
                            historicalGrantAllocationsData
                          }
                          socioeconomicData={socioeconomicData}
                          historicalClaimSum={historicalClaimSum}
                          historicalGrantSum={historicalGrantSum}
                          socioeconomicSum={socioeconomicSum}
                          // futureFloodRiskWeight={sliderValues["setting1"]}
                          historicalClaimWeight={sliderValues["setting2"]}
                          historicalGrantWeight={sliderValues["setting3"]}
                          socioeconomicWeight={sliderValues["setting4"]}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  <Accordion.Item
                    eventKey="0"
                    onClick={() => handleAccordionToggle("0")}
                    className="analysis-accordion"
                  >
                    <Accordion.Header>
                      <div
                        style={{
                          display: "flex",
                          width: "95%",
                          justifyContent: "space-between",
                        }}
                        className="accordion-title"
                      >
                        <div>Future Flood Risk</div>
                        <div>
                          {openModalAnalysisTypes.includes("0")
                            ? "Collapse"
                            : "Expand"}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {selectedAreaOption === "option1" && (
                        <div
                          id="future-flood-risk-arrows"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <SkipPreviousIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 40, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveFutureFloodRiskCarouselIndex(0);
                              setCurrentFutureFloodRiskDropdownCounty(
                                orderedSelectedCountyNames[0]
                              );
                            }}
                          />
                          <ArrowLeftIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 50, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (activeFutureFloodRiskCarouselIndex === 0) {
                                setActiveFutureFloodRiskCarouselIndex(
                                  orderedSelectedCountyNames.length - 1
                                );
                                setCurrentFutureFloodRiskDropdownCounty(
                                  orderedSelectedCountyNames[
                                    orderedSelectedCountyNames.length - 1
                                  ]
                                );
                              } else {
                                setActiveFutureFloodRiskCarouselIndex(
                                  activeFutureFloodRiskCarouselIndex - 1
                                );
                                setCurrentFutureFloodRiskDropdownCounty(
                                  orderedSelectedCountyNames[
                                    activeFutureFloodRiskCarouselIndex - 1
                                  ]
                                );
                              }
                            }}
                          />
                          <Dropdown
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            onSelect={(e) =>
                              setActiveFutureFloodRiskCarouselIndex(Number(e))
                            }
                          >
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                            >
                              {currentFutureFloodRiskDropdownCounty
                                ? currentFutureFloodRiskDropdownCounty
                                : "Select County"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {Object.values(macroAnalysisData).length &&
                                futureFloodRiskData.map((county, index) => {
                                  return (
                                    <Dropdown.Item
                                      eventKey={index}
                                      key={`${index}`}
                                      onClick={(e) =>
                                        setCurrentFutureFloodRiskDropdownCounty(
                                          e.target.outerText
                                        )
                                      }
                                    >
                                      {NJCountyCodes[county.code]}
                                    </Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ArrowRightIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 50, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                activeFutureFloodRiskCarouselIndex ===
                                orderedSelectedCountyNames.length - 1
                              ) {
                                setActiveFutureFloodRiskCarouselIndex(0);
                                setCurrentFutureFloodRiskDropdownCounty(
                                  orderedSelectedCountyNames[0]
                                );
                              } else {
                                setActiveFutureFloodRiskCarouselIndex(
                                  activeFutureFloodRiskCarouselIndex + 1
                                );
                                setCurrentFutureFloodRiskDropdownCounty(
                                  orderedSelectedCountyNames[
                                    activeFutureFloodRiskCarouselIndex + 1
                                  ]
                                );
                              }
                            }}
                          />
                          <SkipNextIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 40, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveFutureFloodRiskCarouselIndex(
                                orderedSelectedCountyNames.length - 1
                              );
                              setCurrentFutureFloodRiskDropdownCounty(
                                orderedSelectedCountyNames[
                                  orderedSelectedCountyNames.length - 1
                                ]
                              );
                            }}
                          />
                        </div>
                      )}
                      {Object.values(macroAnalysisData).length &&
                      selectedAreaOption === "option1" ? (
                        <Carousel
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          interval={null}
                          activeIndex={activeFutureFloodRiskCarouselIndex}
                          onSelect={(e) => {
                            setActiveFutureFloodRiskCarouselIndex(e);
                            setCurrentFutureFloodRiskDropdownCounty(
                              orderedSelectedCountyNames[e]
                            );
                          }}
                        >
                          {futureFloodRiskData.map((county, index) => {
                            return (
                              <Carousel.Item key={`chart-${index}`}>
                                <div
                                  style={{
                                    fontSize: 24,
                                    display: "flex",
                                    marginLeft: 50,
                                  }}
                                >
                                  {selectedAreaOption === "option1" ? (
                                    <strong>
                                      County: {NJCountyCodes[county.code]}
                                    </strong>
                                  ) : (
                                    <strong>Census Tract: {county.code}</strong>
                                  )}
                                </div>
                                <div
                                  style={{
                                    border: "1px solid black",
                                    padding: 20,
                                  }}
                                >
                                  <PieChart
                                    data={county}
                                    type="futureFloodRisk"
                                    selectionMode={selectedAreaOption}
                                    key={`${county}-${index}`}
                                  />
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      ) : Object.values(macroAnalysisData).length &&
                        selectedAreaOption === "option2" ? (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Census Tract</th>
                              <th>Total Flood Risk</th>
                              <th>Detailed Plot</th>
                            </tr>
                          </thead>
                          <tbody>
                            {futureFloodRiskData.length &&
                              futureFloodRiskData.map((county) => {
                                return (
                                  <tr key={county.code}>
                                    <td>{county.code}</td>
                                    <td>{county.floodRisk}</td>
                                    <td>
                                      <div
                                        className="plot-table-show"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <OverlayTrigger
                                          placement="bottom-end"
                                          overlay={tableTooltip(
                                            county,
                                            "futureFloodRisk",
                                            selectedAreaOption
                                          )}
                                        >
                                          <strong>Show</strong>
                                        </OverlayTrigger>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      ) : (
                        <div>No future flood risk data found!</div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="1"
                    onClick={() => handleAccordionToggle("1")}
                    className="analysis-accordion"
                  >
                    <Accordion.Header>
                      <div
                        style={{
                          display: "flex",
                          width: "95%",
                          justifyContent: "space-between",
                        }}
                        className="accordion-title"
                      >
                        <div>Historical Flood Damages</div>
                        <div>
                          {openModalAnalysisTypes.includes("1")
                            ? "Collapse"
                            : "Expand"}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {selectedAreaOption === "option1" && (
                        <div
                          id="historical-claims-arrows"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <SkipPreviousIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 40, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveFloodDamageCarouselIndex(0);
                              setCurrentFloodDamageDropdownCounty(
                                orderedSelectedCountyNames[0]
                              );
                            }}
                          />
                          <ArrowLeftIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 50, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (activeFloodDamageCarouselIndex === 0) {
                                setActiveFloodDamageCarouselIndex(
                                  orderedSelectedCountyNames.length - 1
                                );
                                setCurrentFloodDamageDropdownCounty(
                                  orderedSelectedCountyNames[
                                    orderedSelectedCountyNames.length - 1
                                  ]
                                );
                              } else {
                                setActiveFloodDamageCarouselIndex(
                                  activeFloodDamageCarouselIndex - 1
                                );
                                setCurrentFloodDamageDropdownCounty(
                                  orderedSelectedCountyNames[
                                    activeFloodDamageCarouselIndex - 1
                                  ]
                                );
                              }
                            }}
                          />
                          <Dropdown
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            onSelect={(e) =>
                              setActiveFloodDamageCarouselIndex(Number(e))
                            }
                          >
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                            >
                              {currentFloodDamageDropdownCounty
                                ? currentFloodDamageDropdownCounty
                                : "Select County"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {Object.values(macroAnalysisData).length &&
                                historicalClaimsData.map((county, index) => {
                                  return (
                                    <Dropdown.Item
                                      eventKey={index}
                                      key={`${index}`}
                                      onClick={(e) =>
                                        setCurrentFloodDamageDropdownCounty(
                                          e.target.outerText
                                        )
                                      }
                                    >
                                      {NJCountyCodes[county.code]}
                                    </Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ArrowRightIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 50, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                activeFloodDamageCarouselIndex ===
                                orderedSelectedCountyNames.length - 1
                              ) {
                                setActiveFloodDamageCarouselIndex(0);
                                setCurrentFloodDamageDropdownCounty(
                                  orderedSelectedCountyNames[0]
                                );
                              } else {
                                setActiveFloodDamageCarouselIndex(
                                  activeFloodDamageCarouselIndex + 1
                                );
                                setCurrentFloodDamageDropdownCounty(
                                  orderedSelectedCountyNames[
                                    activeFloodDamageCarouselIndex + 1
                                  ]
                                );
                              }
                            }}
                          />
                          <SkipNextIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 40, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveFloodDamageCarouselIndex(
                                orderedSelectedCountyNames.length - 1
                              );
                              setCurrentFloodDamageDropdownCounty(
                                orderedSelectedCountyNames[
                                  orderedSelectedCountyNames.length - 1
                                ]
                              );
                            }}
                          />
                        </div>
                      )}
                      {Object.values(macroAnalysisData).length &&
                      selectedAreaOption === "option1" ? (
                        <Carousel
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          interval={null}
                          activeIndex={activeFloodDamageCarouselIndex}
                          onSelect={(e) => {
                            setActiveFloodDamageCarouselIndex(e);
                            setCurrentFloodDamageDropdownCounty(
                              orderedSelectedCountyNames[e]
                            );
                          }}
                        >
                          {historicalClaimsData.map((county, index) => {
                            return (
                              <Carousel.Item key={`chart-${index}`}>
                                <div
                                  style={{
                                    fontSize: 24,
                                    display: "flex",
                                    marginLeft: 50,
                                  }}
                                >
                                  {selectedAreaOption === "option1" ? (
                                    <strong>
                                      County: {NJCountyCodes[county.code]}
                                    </strong>
                                  ) : (
                                    <strong>Census Tract: {county.code}</strong>
                                  )}
                                </div>
                                <div
                                  style={{
                                    border: "1px solid black",
                                    padding: 20,
                                  }}
                                >
                                  <PieChart
                                    data={county}
                                    type="historicalClaims"
                                    selectionMode={selectedAreaOption}
                                    key={`${county}-${index}`}
                                  />
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      ) : Object.values(macroAnalysisData).length &&
                        selectedAreaOption === "option2" ? (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Census Tract</th>
                              <th>Total Policy Count</th>
                              <th>Detailed Plot</th>
                            </tr>
                          </thead>
                          <tbody>
                            {historicalClaimsData.map((county) => {
                              return (
                                <tr key={county.code}>
                                  <td>{county.code}</td>
                                  <td>{county.policyCountSum}</td>
                                  <td>
                                    <div
                                      className="plot-table-show"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <OverlayTrigger
                                        placement="bottom-end"
                                        overlay={tableTooltip(
                                          county,
                                          "historicalClaims",
                                          selectedAreaOption
                                        )}
                                      >
                                        <strong>Show</strong>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      ) : (
                        <div>No historical flood damage data found!</div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  {selectedAreaOption === "option1" && (
                    <Accordion.Item
                      eventKey="2"
                      onClick={() => handleAccordionToggle("2")}
                      className="analysis-accordion"
                    >
                      <Accordion.Header>
                        <div
                          style={{
                            display: "flex",
                            width: "95%",
                            justifyContent: "space-between",
                          }}
                          className="accordion-title"
                        >
                          <div>Historical Grant Allocations</div>
                          <div>
                            {openModalAnalysisTypes.includes("2")
                              ? "Collapse"
                              : "Expand"}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {selectedAreaOption === "option1" && (
                          <div
                            id="grant-allocation-arrows"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <SkipPreviousIcon
                              className="arrow-nav-icon"
                              style={{ fontSize: 40, color: "#ADBAC3" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveGrantAllocationsCarouselIndex(0);
                                setCurrentGrantAllocationDropdownCounty(
                                  orderedSelectedCountyNames[0]
                                );
                              }}
                            />
                            <ArrowLeftIcon
                              className="arrow-nav-icon"
                              style={{ fontSize: 50, color: "#ADBAC3" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (activeGrantAllocationsCarouselIndex === 0) {
                                  setActiveGrantAllocationsCarouselIndex(
                                    orderedSelectedCountyNames.length - 1
                                  );
                                  setCurrentGrantAllocationDropdownCounty(
                                    orderedSelectedCountyNames[
                                      orderedSelectedCountyNames.length - 1
                                    ]
                                  );
                                } else {
                                  setActiveGrantAllocationsCarouselIndex(
                                    activeGrantAllocationsCarouselIndex - 1
                                  );
                                  setCurrentGrantAllocationDropdownCounty(
                                    orderedSelectedCountyNames[
                                      activeGrantAllocationsCarouselIndex - 1
                                    ]
                                  );
                                }
                              }}
                            />
                            <Dropdown
                              onClick={(e) => e.stopPropagation()}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              onSelect={(e) =>
                                setActiveGrantAllocationsCarouselIndex(
                                  Number(e)
                                )
                              }
                            >
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                              >
                                {currentGrantAllocationDropdownCounty
                                  ? currentGrantAllocationDropdownCounty
                                  : "Select County"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {Object.values(macroAnalysisData).length &&
                                  historicalClaimsData.map((county, index) => {
                                    return (
                                      <Dropdown.Item
                                        eventKey={index}
                                        key={`${index}`}
                                        onClick={(e) =>
                                          setCurrentGrantAllocationDropdownCounty(
                                            e.target.outerText
                                          )
                                        }
                                      >
                                        {NJCountyCodes[county.code]}
                                      </Dropdown.Item>
                                    );
                                  })}
                              </Dropdown.Menu>
                            </Dropdown>
                            <ArrowRightIcon
                              className="arrow-nav-icon"
                              style={{ fontSize: 50, color: "#ADBAC3" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  activeGrantAllocationsCarouselIndex ===
                                  orderedSelectedCountyNames.length - 1
                                ) {
                                  setActiveGrantAllocationsCarouselIndex(0);
                                  setCurrentGrantAllocationDropdownCounty(
                                    orderedSelectedCountyNames[0]
                                  );
                                } else {
                                  setActiveGrantAllocationsCarouselIndex(
                                    activeGrantAllocationsCarouselIndex + 1
                                  );
                                  setCurrentGrantAllocationDropdownCounty(
                                    orderedSelectedCountyNames[
                                      activeGrantAllocationsCarouselIndex + 1
                                    ]
                                  );
                                }
                              }}
                            />
                            <SkipNextIcon
                              className="arrow-nav-icon"
                              style={{ fontSize: 40, color: "#ADBAC3" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveGrantAllocationsCarouselIndex(
                                  orderedSelectedCountyNames.length - 1
                                );
                                setCurrentGrantAllocationDropdownCounty(
                                  orderedSelectedCountyNames[
                                    orderedSelectedCountyNames.length - 1
                                  ]
                                );
                              }}
                            />
                          </div>
                        )}
                        {Object.values(macroAnalysisData).length &&
                        selectedAreaOption === "option1" ? (
                          <Carousel
                            onClick={(e) => e.stopPropagation()}
                            interval={null}
                            activeIndex={activeGrantAllocationsCarouselIndex}
                            onSelect={(e) => {
                              setActiveGrantAllocationsCarouselIndex(e);
                              setCurrentGrantAllocationDropdownCounty(
                                orderedSelectedCountyNames[e]
                              );
                            }}
                          >
                            {historicalGrantAllocationsData.map(
                              (county, index) => {
                                return (
                                  <Carousel.Item key={`${index}`}>
                                    <div
                                      style={{
                                        fontSize: 24,
                                        display: "flex",
                                        marginLeft: 50,
                                      }}
                                    >
                                      {selectedAreaOption === "option1" ? (
                                        <strong>
                                          County: {NJCountyCodes[county.code]}
                                        </strong>
                                      ) : (
                                        <strong>
                                          Census Tract: {county.code}
                                        </strong>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        border: "1px solid black",
                                        padding: 20,
                                      }}
                                    >
                                      <PieChart
                                        data={county}
                                        type="historicalGrantAllocations"
                                        selectionMode={selectedAreaOption}
                                        key={`${county}-${index}`}
                                      />
                                    </div>
                                  </Carousel.Item>
                                );
                              }
                            )}
                          </Carousel>
                        ) : (
                          <div>No historical flood damage data found!</div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  <Accordion.Item
                    eventKey="3"
                    onClick={() => handleAccordionToggle("3")}
                    className="analysis-accordion"
                  >
                    <Accordion.Header>
                      <div
                        style={{
                          display: "flex",
                          width: "95%",
                          justifyContent: "space-between",
                        }}
                        className="accordion-title"
                      >
                        <div>Socioeconomic Vulnerabilities</div>
                        <div>
                          {openModalAnalysisTypes.includes("3")
                            ? "Collapse"
                            : "Expand"}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {selectedAreaOption === "option1" && (
                        <div
                          id="socioeconomic-arrows"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <SkipPreviousIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 40, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveSocioeconomicCarouselIndex(0);
                              setCurrentSocioeconomicDropdownCounty(
                                orderedSelectedCountyNames[0]
                              );
                            }}
                          />
                          <ArrowLeftIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 50, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (activeSocioeconomoicCarouselIndex === 0) {
                                setActiveSocioeconomicCarouselIndex(
                                  orderedSelectedCountyNames.length - 1
                                );
                                setCurrentSocioeconomicDropdownCounty(
                                  orderedSelectedCountyNames[
                                    orderedSelectedCountyNames.length - 1
                                  ]
                                );
                              } else {
                                setActiveSocioeconomicCarouselIndex(
                                  activeSocioeconomoicCarouselIndex - 1
                                );
                                setCurrentSocioeconomicDropdownCounty(
                                  orderedSelectedCountyNames[
                                    activeSocioeconomoicCarouselIndex - 1
                                  ]
                                );
                              }
                            }}
                          />
                          <Dropdown
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            onSelect={(e) =>
                              setActiveSocioeconomicCarouselIndex(Number(e))
                            }
                          >
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                            >
                              {currentSocioeconomicDropdownCounty
                                ? currentSocioeconomicDropdownCounty
                                : "Select County"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {Object.values(macroAnalysisData).length &&
                                historicalClaimsData.map((county, index) => {
                                  return (
                                    <Dropdown.Item
                                      eventKey={index}
                                      key={`${index}`}
                                      onClick={(e) =>
                                        setCurrentSocioeconomicDropdownCounty(
                                          e.target.outerText
                                        )
                                      }
                                    >
                                      {NJCountyCodes[county.code]}
                                    </Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ArrowRightIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 50, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                activeSocioeconomoicCarouselIndex ===
                                orderedSelectedCountyNames.length - 1
                              ) {
                                setActiveSocioeconomicCarouselIndex(0);
                                setCurrentSocioeconomicDropdownCounty(
                                  orderedSelectedCountyNames[0]
                                );
                              } else {
                                setActiveSocioeconomicCarouselIndex(
                                  activeSocioeconomoicCarouselIndex + 1
                                );
                                setCurrentSocioeconomicDropdownCounty(
                                  orderedSelectedCountyNames[
                                    activeSocioeconomoicCarouselIndex + 1
                                  ]
                                );
                              }
                            }}
                          />
                          <SkipNextIcon
                            className="arrow-nav-icon"
                            style={{ fontSize: 40, color: "#ADBAC3" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveSocioeconomicCarouselIndex(
                                orderedSelectedCountyNames.length - 1
                              );
                              setCurrentSocioeconomicDropdownCounty(
                                orderedSelectedCountyNames[
                                  orderedSelectedCountyNames.length - 1
                                ]
                              );
                            }}
                          />
                        </div>
                      )}
                      {Object.values(macroAnalysisData).length &&
                      selectedAreaOption === "option1" ? (
                        <Carousel
                          onClick={(e) => e.stopPropagation()}
                          interval={null}
                          activeIndex={activeSocioeconomoicCarouselIndex}
                          onSelect={(e) => {
                            setActiveSocioeconomicCarouselIndex(e);
                            setCurrentSocioeconomicDropdownCounty(
                              orderedSelectedCountyNames[e]
                            );
                          }}
                        >
                          {socioeconomicData.map((county, index) => {
                            return (
                              <Carousel.Item key={`${index}`}>
                                <div
                                  style={{
                                    fontSize: 24,
                                    display: "flex",
                                    marginLeft: 50,
                                  }}
                                >
                                  {selectedAreaOption === "option1" ? (
                                    <strong>
                                      County: {NJCountyCodes[county.code]}
                                    </strong>
                                  ) : (
                                    <strong>Census Tract: {county.code}</strong>
                                  )}
                                </div>
                                <div
                                  style={{
                                    border: "1px solid black",
                                    padding: 20,
                                  }}
                                >
                                  <PieChart
                                    data={county}
                                    type="socioeconomic"
                                    selectionMode={selectedAreaOption}
                                    key={`${index}`}
                                  />
                                </div>
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      ) : Object.values(macroAnalysisData).length &&
                        selectedAreaOption === "option2" ? (
                        <CTBarChart
                          data={socioeconomicData}
                          type="socioeconomic"
                          onClick={(e) => e.stopPropagation()}
                        />
                      ) : (
                        <div>No historical flood damage data found!</div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div
                  style={{
                    display: "flex",
                    bottom: 0,
                    right: 50,
                    backgroundColor: "rgba(253, 148, 38, 0.15)",
                    color: "black",
                    height: 60,
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: 100,
                    paddingRight: 100,
                  }}
                >
                  <strong>
                    <a
                      href="https://www.timbalierresources.com"
                      target="_blank"
                    >
                      www.timbalierresources.com
                    </a>
                  </strong>
                  <img
                    src={require("../assets/tr_initials.png")}
                    style={{ height: 60 }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <EsriMap
          onNamesRetrieved={handleNamesRetrieved}
          onCountyRectangleSelect={changeCountiesByRectangle}
          onCensusTractRectangleSelect={changeCensusTractsByRectangle}
          changeSelectionData={setMapSelectionData}
          selectedNameLSADs={selectedNameLSADs}
          selectedCountyNames={selectedCountyNames}
          selectedAreaOption={selectedAreaOption}
          mapPrinting={mapPrinting}
          htmlDownloading={htmlDownloading}
          printMap={setMapPrinting}
          downloadHtml={setHTMLDownloading}
          weightedSums={weightedSums}
          onGradientColorChange={handleGradientColorChange}
        />
      </div>
    </div>
  );
};

export default withAuth(NJMacro);
