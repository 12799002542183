import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import exporting from "highcharts/modules/exporting";

exporting(Highcharts);

const CTBarChart = ({ data, type }) => {
  const censusTractList = Object.values(data).map(
    (censusTract) => Object.keys(censusTract)[0]
  );

  const seriesData = [
    {
      name: "Census Tracts",
      colorByPoint: true,
      data:
        type === "socioeconomic"
          ? Object.values(data).map((censusTract) => ({
              name: Object.keys(censusTract)[0],
              y: censusTract.sevScore,
            }))
          : null,
    },
  ];

  const options = {
    chart: {
      type: "column",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: "Census Tracts Disadvantage Status and EV Score",
      align: "left",
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        return `
          SEV Score: <b>${point.y}</b>
        `;
      },
    },
    xAxis: {
      categories: censusTractList,
      title: {
        text: "Census Tract",
      },
    },
    yAxis: {
      title: {
        text: "SEV Score",
      },
      max: 1,
    },
    plotOptions: {
      bar: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: seriesData,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CTBarChart;
