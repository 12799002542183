import React, { useEffect, useState } from "react";

const ColorBar = ({ colors, weightedSums }) => {
  const [weightedSumValues, setWeightedSumValues] = useState([]);

  useEffect(() => {
    setWeightedSumValues(
      Object.values(weightedSums)
        .map((sum) => sum.toFixed(3))
        .sort((a, b) => b - a)
    );
  }, [weightedSums]);

  return (
    <div
      className="color-bar"
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <div style={{ display: "flex", margin: "0 auto" }}>Weighted Sum</div>
      <div style={{ display: "flex", overflowX: "auto", justifyContent: "center" }}>
        {colors.map((color, index) => (
          <div
            key={index}
            className="color-block"
            style={{
              backgroundColor: color.includes("rgb") ? color : `rgb(${String(color)})`,
              width: `${100 / colors.length}%`,
              height: 20,
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
            }}
          ></div>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{weightedSumValues && weightedSumValues[0]}</div>
        <div>
          {weightedSumValues.length > 2
            ? weightedSumValues &&
              weightedSumValues[Math.ceil(weightedSumValues.length / 2) - 1]
            : null}
        </div>
        <div>
          {weightedSumValues.length > 1
            ? weightedSumValues &&
              weightedSumValues[weightedSumValues.length - 1]
            : null}
        </div>
      </div>
    </div>
  );
};

export default ColorBar;
