import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import styled from "styled-components";

import { DASHBOARD_BLUE } from "../../constants";
import { normalizeString } from "../../helpers/dashboard";
import { LocationBoxProps } from "../../typeInterfaces/crsProps";

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${DASHBOARD_BLUE};
  border-radius: 5px;
  text-align: left;
  margin-top: 20px;
  color: ${DASHBOARD_BLUE};
`;

const LocationBoxLine = styled.div`
  display: flex;
  font-size: 14px;

  .leftText {
    margin-right: 5px;
    font-weight: bold;
  }
`;

const LocationBoxText = styled.div`
  flex: 1;
  text-align: left;
`;

const LocationBox: React.FC<LocationBoxProps> = ({
  state,
  city,
  communityId,
  cavDate,
}) => {
  const [renderedCity, setRenderedCity] = useState<string>("");

  useEffect(() => {
    if (city) {
      if (city.includes(",")) {
        setRenderedCity(
          normalizeString(city.split(",")[1] + " " + city.split(",")[0])
        );
      } else {
        setRenderedCity(normalizeString(city));
      }
    }
  }, [city]);

  return (
    <LocationWrapper>
      <div
        style={{
          backgroundColor: DASHBOARD_BLUE,
          color: "white",
          padding: "10px",
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          fontSize: 16,
        }}
      >
        {city ? renderedCity : "Select community for info"}
      </div>
      <div style={{ padding: 20 }}>
        <LocationBoxLine>
          <LocationBoxText className="leftText">State:</LocationBoxText>
          <div style={{ flex: 1, fontSize: 14 }}>{state}</div>
        </LocationBoxLine>
        <LocationBoxLine>
          <LocationBoxText className="leftText">Community ID:</LocationBoxText>
          <div style={{ flex: 1, fontSize: 14 }}>{communityId}</div>
        </LocationBoxLine>
        <LocationBoxLine>
          <LocationBoxText className="leftText">CAV Date:</LocationBoxText>
          <div style={{ flex: 1, fontSize: 14 }}>{cavDate}</div>
        </LocationBoxLine>
      </div>
    </LocationWrapper>
  );
};

export default LocationBox;
