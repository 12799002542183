import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import { DASHBOARD_BLUE } from "../../constants";

exporting(Highcharts);

const PieChart = ({ data, type, selectionMode }) => {
  const chartRef = useRef(null);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    if (type === "socioeconomic") {
      setCurrentData([
        {
          name: "Disadvantaged",
          y: data.disadvantagedCount.Disadvantaged,
          color: "#AEC6CF",
        },
        {
          name: "Not Disadvantaged",
          y: data.disadvantagedCount["Not Disadvantaged"],
          color: "#FF6961",
        },
        {
          name: "Partially Disadvantaged",
          y: data.disadvantagedCount["Partially Disadvantaged"],
          color: DASHBOARD_BLUE,
        },
      ]);
    } else if (type === "historicalClaims" && selectionMode === "option1") {
      setCurrentData(
        data.policyCountSumYearly &&
          Object.entries(data.policyCountSumYearly).map(([year, value]) => ({
            name: Math.round(year),
            y: value,
          }))
      );
    } else if (type === "historicalClaims" && selectionMode === "option2") {
      setCurrentData(
        data.policyCountSumYearly &&
          Object.entries(data.policyCountSumYearly).map((censusTract) => ({
            name: censusTract[0],
            y: censusTract[1],
          }))
      );
    } else if (type === "historicalGrantAllocations") {
      setCurrentData(
        data.propertiesCountYearly &&
          Object.entries(data.propertiesCountYearly).map(([year, value]) => ({
            name: Math.round(year),
            y: value,
          }))
      );
    } else if (type === "futureFloodRisk") {
      setCurrentData([
        {
          name: "Flood Risk",
          y: data.length && data.floodRisk,
          color: "#AEC6CF",
        },
      ]);
    } else {
      setCurrentData([]);
    }
  }, [data]);

  let currentCount = "";

  if (type === "socioeconomic") {
    currentCount =
      data.disadvantagedCount.Disadvantaged +
      data.disadvantagedCount["Not Disadvantaged"];
  } else if (type === "historicalClaims" && selectionMode === "option2") {
    currentCount = data.policyCountSum;
  } else if (type === "historicalClaims") {
    currentCount = data.policyCountSum;
  } else if (type === "futureFloodRisk") {
    currentCount = data.floodRisk;
  } else {
    currentCount = data.propertiesCount;
  }

  const text =
    type === "socioeconomic"
      ? "Total CT Count"
      : type === "historicalGrantAllocations"
      ? "Total Properties Count"
      : type === "historicalClaims" && selectionMode === "option2"
      ? "Total Policy Count"
      : type === "futureFloodRisk"
      ? "Total Properties"
      : "Total NFIP Policies";

  const seriesData = [
    {
      name:
        type === "socioeconomic"
          ? "CT's"
          : type === "historicalGrantAllocations"
          ? "Properties"
          : type === "historicalClaims"
          ? "Policies"
          : "Regions",
      colorByPoint: true,
      data: currentData,
      innerSize: "50%",
    },
  ];

  const options = {
    chart: {
      type: "pie",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text:
        selectionMode === "option2"
          ? `
      <div style="text-align:center; font-size: 8px; max-width: 10px; "><div style="width: 50px;">${text}</div> <br> ${currentCount}</div>
      `
          : `
      <div style="text-align:center; font-size: 12px; max-width: 10px; "><div style="width: 50px;">${text}</div> <br> ${currentCount}</div>
      `,
      align: "center",
      verticalAlign: "middle",
      y: 10,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}% ({point.y})</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: `<div style="display: flex; justify-content: center;"><b>{point.name}</b>: {point.percentage:.1f}% <br> {point.y}</div>`,
        },
      },
    },
    series: seriesData,
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
};

export default PieChart;
