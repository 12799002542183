import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import styled from "styled-components";
import Select from "react-select";

import {
  DASHBOARD_BLUE,
  DASHBOARD_ORANGE,
  stateAbbreviations,
} from "../../constants";
import { normalizeString } from "../../helpers/dashboard";
import { LocationInputProps } from "../../typeInterfaces/crsProps";

const LocationWrapper = styled.div`
  border: 1px solid ${DASHBOARD_ORANGE};
  border-radius: 5px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .titleText {
    color: ${DASHBOARD_BLUE};
    margin-right: 5px;
    text-align: left;
    white-space: nowrap;
    flex: 1;
    font-size: 14px;
    font-weight: bold;
  }
`;

const LocationInput: React.FC<LocationInputProps> = ({
  stateList,
  communityList,
  onStateChange,
  onCityChange,
  state,
  city,
}) => {
  const [stateOptions, setStateOptions] = useState<
    { value: string; label: string }[] | undefined
  >();
  const [communityOptions, setCommunityOptions] = useState<
    { value: string; label: string }[] | undefined
  >();

  useEffect(() => {
    onCityChange("");
  }, [state]);

  useEffect(() => {
    if (stateList !== undefined) {
      setStateOptions(
        Array.from(stateList)
          .map((item) => ({
            value: item,
            label: `${item} - ${stateAbbreviations[item as keyof object]}`,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [stateList]);

  useEffect(() => {
    if (communityList) {
      const communities = Array.from(communityList)
        .map((item) => ({
          value: item,
          label: item.includes(",")
            ? normalizeString(item.split(",")[1] + " " + item.split(",")[0])
            : normalizeString(item),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setCommunityOptions(communities);
    }
  }, [communityList, state]);

  return (
    <LocationWrapper style={{ marginTop: 10 }}>
      <div
        style={{
          backgroundColor: DASHBOARD_ORANGE,
          color: "white",
          width: "100%",
          padding: "10px",
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          fontSize: 16,
        }}
      >
        Select Your Community
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
          padding: "15px 0px 15px 0px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <div id="state" className="titleText">
            State
          </div>
          <Select
            // styles={{
            //   control: (baseStyles, state) => ({
            //     ...baseStyles,
            //     borderColor: state.isFocused ? "black" : "grey",
            //     width: 140,
            //     textAlign: "left",
            //     fontSize: 14,
            //   }),
            // }}
            options={stateOptions}
            onChange={(e) => onStateChange(e?.value)}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div id="city" className="titleText">
            Community
          </div>
          <Select
            // styles={{
            //   control: (baseStyles, state) => ({
            //     ...baseStyles,
            //     borderColor: state.isFocused ? "black" : "grey",
            //     width: 140,
            //     textAlign: "left",
            //     fontSize: 14,
            //   }),
            // }}
            options={communityOptions || []}
            value={
              communityOptions?.find((option) => option.value === city)
                ? communityOptions?.find((option) => option.value === city)
                : null
            }
            onChange={(selectedOption) => onCityChange(selectedOption?.value)}
          />
        </div>
      </div>
    </LocationWrapper>
  );
};

export default LocationInput;
