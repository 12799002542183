import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import axios from "axios";

import {
  APPLICATION_URL,
  DASHBOARD_BLUE,
  DASHBOARD_ORANGE,
  crsRatings,
} from "../../constants";
import { normalizeString } from "../../helpers/dashboard";
import { SavingsInputProps } from "../../typeInterfaces/crsProps";

const SavingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #2e5a88;
  border-radius: 5px;

  text-align: left;
  margin-top: 20px;
  font-size: 14px;

  .button:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 0.2s;
  }

  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    min-height: unset;
    height: 50px;
    padding-bottom: 0px;
    padding-top: 10px;
    display: flex;
    margin: auto;
  }

  .form-floating > label {
    top: -1px;
  }

  .leftText {
    font-weight: bold;
    text-align: left;
  }
`;

const PotentialSavingsBlock = styled.div`
  color: ${DASHBOARD_BLUE};
  font-weight: bold;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const SavingsDropdown = styled(Form.Select)`
  color: black;
  font-size: 14px;
  display: flex;
`;

const SavingsInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2%;
  align-items: center;
`;

const Savings: React.FC<SavingsInputProps> = ({
  state,
  community,
  communityId,
  county,
  crsRating,
  onCalculationPendingChange,
  onCrsSeriesDataChange,
  onCrsCategoryDataChange,
  onSavingsDataError,
}) => {
  const [savingsData, setSavingsData] = useState<object | undefined>();
  const [currentRating, setCurrentRating] = useState<number | undefined>(0);
  const [renderedCommunity, setRenderedCommunity] = useState<string>("");

  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedRating, setSelectedRating] = useState<string | undefined>("");

  const [showSavingsLoadingModal, setShowSavingsLoadingModal] =
    useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const rrRating: number | undefined = currentRating
    ? currentRating
    : undefined;

  const calculateCrsSavings = async (
    usState: string | null | undefined,
    community: string | null | undefined,
    county: string | null | undefined,
    communityId: string | null | undefined
  ) => {
    const timeout = setTimeout(() => {
      if (!isDataLoaded) {
        onSavingsDataError(true);
        setShowSavingsLoadingModal(false);
        setSavingsData([]);
        console.log("Data fetching timed out");
      }
    }, 60000);

    try {
      setIsDataLoaded(false);
      const response = await axios.get(
        `${APPLICATION_URL}/api/fetchSavingsAndCrsScoringData`,
        {
          params: {
            state: usState,
            communityName: community,
            county: county,
            communityId: communityId,
          },
        }
      );
      setSavingsData(response.data[0]);
      setCurrentRating(response.data[1]["pct_increase"]);

      onCrsSeriesDataChange(response.data[2]);
      onCrsCategoryDataChange(response.data[3]);
      if (community) {
        if (community.includes(",")) {
          setRenderedCommunity(
            normalizeString(
              community.split(",")[1] + " " + community.split(",")[0]
            )
          );
        } else {
          setRenderedCommunity(normalizeString(community));
        }
      }
      setShowSavingsLoadingModal(false);
      setIsDataLoaded(true);
      onSavingsDataError(false);
      clearTimeout(timeout);
    } catch (error) {
      console.error(error);
    }
  };

  const yearsData =
    savingsData &&
    Object.values(savingsData).length &&
    Object.values(savingsData);
  const mostRecentYearData =
    yearsData && yearsData.reduce((a, b) => (a.year > b.year ? a : b));
  const selectedYearData = selectedYear
    ? yearsData &&
      yearsData.find(
        (year) => String(year["year" as keyof object]) === String(selectedYear)
      )
    : mostRecentYearData;

  const adjustedPremiums: number | undefined =
    rrRating &&
    mostRecentYearData &&
    selectedYearData &&
    Object.values(selectedYearData).length &&
    Object.values(mostRecentYearData).length &&
    rrRating &&
    mostRecentYearData &&
    Object.values(mostRecentYearData).length
      ? (1 + rrRating / 100) * selectedYearData["sum" as keyof object]
      : rrRating &&
        mostRecentYearData &&
        Object.values(mostRecentYearData).length &&
        selectedYearData &&
        Object.values(selectedYearData).length
      ? (1 + rrRating / 100) * selectedYearData["sum" as keyof object]
      : 0;

  const premiumReduction: number = 0.5 - 0.05 * Number(selectedRating);

  const potentialAnnualSavings: number | undefined = Math.round(
    adjustedPremiums * premiumReduction
  );

  useEffect(() => {
    mostRecentYearData &&
      setSelectedYear(selectedYearData["year" as keyof object]);
    mostRecentYearData && crsRating !== "N/A"
      ? setSelectedRating(crsRating)
      : setSelectedRating("8");
  }, [mostRecentYearData]);

  return (
    <SavingsWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: DASHBOARD_BLUE,
          color: "white",
          padding: "10px",
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          alignItems: "center",
        }}
      >
        {renderedCommunity ? (
          <span style={{ fontSize: 16, width: "70%" }}>
            Savings - {renderedCommunity}
          </span>
        ) : (
          <span style={{ fontSize: 16 }}>Savings</span>
        )}
        <Button
          className="button"
          style={{
            backgroundColor: DASHBOARD_ORANGE,
            color: "white",
            borderRadius: 5,
            border: `1px solid ${DASHBOARD_ORANGE}`,
            fontSize: 16,
          }}
          onClick={() => {
            onCalculationPendingChange(false);
            setShowSavingsLoadingModal(true);
            calculateCrsSavings(state, community, county, communityId);
          }}
        >
          Calculate
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 10,
        }}
      >
        {savingsData ? (
          <div
            key={selectedYearData && selectedYearData["year" as keyof object]}
            style={{ color: "#264E73" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <div className="leftText">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Year"
                  style={{ padding: 5, color: "black", fontSize: 14 }}
                >
                  <SavingsDropdown
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    style={{
                      borderColor: DASHBOARD_BLUE,
                      color: "black",
                    }}
                  >
                    {yearsData &&
                      yearsData
                        .sort(
                          (a, b) =>
                            b["year" as keyof object] -
                            a["year" as keyof object]
                        )
                        .map((year) => (
                          <option
                            key={year["year" as keyof object]}
                            value={year["year" as keyof object]}
                            style={{ color: "black" }}
                          >
                            {year["year" as keyof object]}
                          </option>
                        ))}
                  </SavingsDropdown>
                </FloatingLabel>
              </div>
              <div className="leftText">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rating"
                  style={{ padding: 5 }}
                >
                  <SavingsDropdown
                    value={selectedRating}
                    onChange={(e) => setSelectedRating(e.target.value)}
                    style={{
                      borderColor: DASHBOARD_BLUE,
                    }}
                  >
                    {crsRatings.map((rating) => (
                      <option key={rating} value={rating}>
                        {rating}
                      </option>
                    ))}
                  </SavingsDropdown>
                </FloatingLabel>
              </div>
            </div>
            <SavingsInfoGrid>
              <div className="leftText">Annual Premiums:</div>
              <div style={{ textAlign: "left" }}>
                {"$"}
                {selectedYearData && Object.values(selectedYearData).length
                  ? selectedYearData["sum" as keyof object].toLocaleString()
                  : mostRecentYearData &&
                    Object.values(mostRecentYearData).length
                  ? mostRecentYearData["sum" as keyof object].toLocaleString()
                  : "N/A"}
              </div>

              <div className="leftText">RR 2.0 Adjustment:</div>
              <div style={{ textAlign: "left" }}>
                {currentRating ? `${rrRating && rrRating.toFixed(2)}%` : "100%"}
              </div>

              <div className="leftText">Adjusted Premiums:</div>
              <div style={{ textAlign: "left" }}>
                ${Math.round(adjustedPremiums).toLocaleString()}
              </div>
            </SavingsInfoGrid>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            Select a community to see savings data!
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgb(38,78,115, 0.15)",
          borderTop: `1px solid ${DASHBOARD_BLUE}`,
          padding: 5,
        }}
      >
        <PotentialSavingsBlock>
          <span style={{ fontSize: 16 }}>Potential Annual Savings</span>
          <span style={{ fontSize: 16 }}>
            ${potentialAnnualSavings?.toLocaleString()}
          </span>
        </PotentialSavingsBlock>
      </div>
      <Modal show={showSavingsLoadingModal}>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../assets/crs_loader.gif")}
            style={{ width: "50%" }}
            alt="CRS Data Loading GIF"
          />
          <div style={{ marginTop: "1%" }}>Loading CRS Data...</div>
        </Modal.Body>
      </Modal>
    </SavingsWrapper>
  );
};

export default Savings;
