import React from "react";
import "../../css/nj-macro/CheckboxWithPopout.css";

function CheckboxWithPopout({ isChecked: propIsChecked, onChange }) {
  const handleCheckboxChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={propIsChecked}
          onChange={handleCheckboxChange}
        />
      </label>
    </div>
  );
}

export default CheckboxWithPopout;
