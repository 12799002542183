export function normalizeString(inputString: string) {
  const words = inputString.split(/\s+/);

  const normalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  const normalizedString = normalizedWords.join(" ").replaceAll("*", "");

  return normalizedString;
}
