import React, { useEffect, useRef, useState } from "react";
import "../../css/nj-macro/EsriMap.css";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
import esriConfig from "@arcgis/core/config.js";
import Map from "@arcgis/core/Map.js";
import MapView from "@arcgis/core/views/MapView.js";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer.js";
import Graphic from "@arcgis/core/Graphic.js";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol.js";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync.js";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel.js";
import Print from "@arcgis/core/widgets/Print.js";

import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";

const EsriMap = ({
  onNamesRetrieved,
  onCountyRectangleSelect,
  onCensusTractRectangleSelect,
  changeSelectionData,
  selectedNameLSADs,
  selectedAreaOption,
  selectedCountyNames,
  mapPrinting,
  htmlDownloading,
  printMap,
  downloadHtml,
  weightedSums,
  onGradientColorChange,
}) => {
  const flRef = useRef(null);
  const flRef2 = useRef(null);
  const resultsLayerRef = useRef(null);
  const resultsLayerRef2 = useRef(null);
  const GraphicRef = useRef(null);
  const SimpleFillSymbolRef = useRef(null);
  const sketchViewModelRef = useRef(null);
  const mapDiv = useRef(null);

  const [currentMapDataToSend, setCurrentMapDataToSend] = useState([]);
  const [currentFeatures, setCurrentFeatures] = useState([]);
  const [printVisible, setPrintVisible] = useState(false);

  function selectFeatures(geometry) {
    const query = {
      geometry: geometry,
      outFields: ["*"],
    };

    flRef.current
      .queryFeatures(query)
      .then((results) => {
        if (results.features.length === 0) {
          // clearSelection();
        } else {
          // results.features = results.features.filter((feature) => {
          //   selectedCountyNames.includes(feature.attributes["COUNTYNAME"]);
          // })
          let graphicsToAdd = results.features.map((feature) => {
            return new GraphicRef.current({
              geometry: feature.geometry,
              symbol: new SimpleFillSymbolRef.current({
                color: "orange",
                style: "solid",
                outline: {
                  color: "black",
                  width: 1,
                },
              }),
              attributes: feature.attributes,
            });
          });

          setCurrentFeatures(results.features);

          const countySet = new Set();
          const censusTractSet = new Set();

          graphicsToAdd.map((graphic) => {
            if (
              selectedAreaOption === "option1" &&
              graphic.attributes.COUNTYNAME
            ) {
              countySet.add(graphic.attributes.COUNTYNAME);
              return countySet;
            } else {
              if (graphic.attributes.CT_Name) {
                countySet.add(graphic.attributes.COUNTYNAME);
                censusTractSet.add(graphic.attributes.CT_Name);

                return censusTractSet;
              }
            }
          });

          if (selectedAreaOption === "option1") {
            onCountyRectangleSelect(countySet);
          } else {
            onCountyRectangleSelect(countySet);
            onCensusTractRectangleSelect(censusTractSet);
          }

          resultsLayerRef.current.addMany(graphicsToAdd);
        }
      })
      .catch(errorCallback);
  }

  function errorCallback(error) {
    console.log("error happened:", error.message);
  }

  const data_to_send = [];

  const mapFeatureToData = (feature) => {
    const stateCode = feature.FIPS_ST;
    const countyCode = feature.FIPS_COU;
    const censusTractCode = feature.FIPS_CT;
    const geoId = feature.GEOID;
    const countyName = feature["COUNTYNAME"];

    const existingCounty = data_to_send.find(
      (county) =>
        county.state.stateCode === stateCode &&
        county.state.county.countyCode === countyCode
    );
    if (existingCounty) {
      if (
        !existingCounty.state.county.selectedTracts.includes(censusTractCode)
      ) {
        if (
          selectedAreaOption === "option2" &&
          selectedNameLSADs.includes("Census Tract " + censusTractCode)
        ) {
          existingCounty.state.county.selectedTracts.push(censusTractCode);
          existingCounty.state.county.selectedGeoIds.push(geoId);
        }
      }
    } else {
      const newCounty = {
        state: {
          stateCode,
          county: {
            countyCode,
            countyName: countyName,
            selectedTracts: [],
            selectedGeoIds: [],
          },
        },
      };

      data_to_send.push(newCounty);
    }
    return data_to_send;
  };

  useEffect(() => {
    if (mapPrinting) {
      downloadAsPDF();
    }
  }, [mapPrinting]);

  useEffect(() => {
    if (htmlDownloading) {
      downloadAsHTML();
    }
  }, [mapPrinting]);

  const downloadAsPDF = async () => {
    if (!mapDiv.current) {
      console.error("Ref not properly assigned");
      return;
    }

    htmlToImage
      .toPng(mapDiv.current)
      .then(function (dataUrl) {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, "PNG", 10, 10, 180, 150);
        pdf.save("image.pdf");
        printMap(false);
      })
      .catch(function (error) {
        console.error("Conversion error:", error);
      });
  };

  const downloadAsHTML = async () => {
    if (!mapDiv.current) {
      console.error("Ref not properly assigned");
      return;
    }

    const htmlContent = mapDiv.current.outerHTML;

    const blob = new Blob([htmlContent], { type: "text/html" });

    const url = URL.createObjectURL(blob);

    window.open(url, "_blank");
    downloadHtml(false);
  };

  const togglePrint = () => {
    setPrintVisible(!printVisible);
    const printWidget = document.querySelector(".esri-print");
    if (printWidget) {
      printWidget.style.display = printVisible ? "none" : "block";
    }
  };

  useEffect(() => {
    const featureLayer = flRef.current;

    if (!featureLayer) {
      // Ensure that the feature layer is available before attempting to query
      return;
    }

    // Your query parameters
    const query = {
      where: "1=1", // Add your specific query conditions
      outFields: ["*"], // Fields to include in the results
    };

    featureLayer
      .queryFeatures(query)
      .then((result) => {
        const features = result.features;
        setCurrentFeatures(
          features.filter((feature) =>
            selectedCountyNames.includes(feature.attributes["COUNTYNAME"])
          )
        );
      })
      .catch((error) => {
        console.error("Error querying features:", error);
      });
  }, [selectedCountyNames]);

  useEffect(() => {
    setCurrentFeatures(
      currentFeatures.filter((feature) =>
        selectedCountyNames.includes(feature.attributes["COUNTYNAME"])
      )
    );
  }, [selectedCountyNames, selectedNameLSADs]);

  useEffect(() => {
    currentFeatures.forEach((feature) => mapFeatureToData(feature.attributes));
    setCurrentMapDataToSend(data_to_send);
  }, [currentFeatures]);

  useEffect(() => {
    changeSelectionData(currentMapDataToSend);
  }, [currentMapDataToSend]);

  useEffect(() => {
    esriConfig.apiKey =
      "AAPK3e585dcec2c547bcba686a6b87aed6f36KOHublo35tFA1nMczN-lCOsUrYwrpu3edE8_ENKNRPeWYavd0U500w4o_71mPBs";

    flRef.current = new FeatureLayer({
      portalItem: {
        id: "5a72de4fb22e438dab2b136213122db6",
      },
      outFields: ["*"],
      visible: false,
    });

    flRef2.current = new FeatureLayer({
      portalItem: {
        id: "9a9618de2400465fbd23a1656dde18b3",
      },
      outFields: ["*"],
      visible: true,
    });

    resultsLayerRef.current = new GraphicsLayer();
    resultsLayerRef2.current = new GraphicsLayer();
    GraphicRef.current = Graphic;
    SimpleFillSymbolRef.current = SimpleFillSymbol;

    const map = new Map({
      layers: [
        flRef.current,
        flRef2.current,
        resultsLayerRef.current,
        resultsLayerRef2.current,
      ],
      basemap: "oceans",
    });

    const view = new MapView({
      map: map,
      zoom: 8,
      container: "viewDiv",
      center: [-74.5, 40],
    });

    const polygonGraphicsLayer = new GraphicsLayer({
      view: view,
    });
    map.add(polygonGraphicsLayer);

    sketchViewModelRef.current = new SketchViewModel({
      view: view,
      layer: polygonGraphicsLayer,
    });

    sketchViewModelRef.current.on("create", async (event) => {
      if (event.state === "complete") {
        const geometries = polygonGraphicsLayer.graphics.map(function (
          graphic
        ) {
          return graphic.geometry;
        });
        const queryGeometry = await geometryEngineAsync.union(
          geometries.toArray()
        );
        selectFeatures(queryGeometry);
      }
    });

    view
      .when(() => {
        const print = new Print({
          view: view,
          printServiceUrl:
            "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
        });

        view.ui.add(print, "top-right");

        print.container.style.display = "none";

        return fetchAllFeatures(flRef.current);
      })
      .then((allFeatures) => {
        const allAttributes = allFeatures.map((feature) => feature.attributes);
        return allAttributes;
      })
      .then(sendToParent);

    view.ui.add("select-by-rectangle", "top-left");
    const selectButton = document.getElementById("select-by-rectangle");

    selectButton.addEventListener("click", () => {
      view.closePopup();
      sketchViewModelRef.current.create("rectangle");
    });

    view.ui.add("clear-selection", "top-left");
    document.getElementById("clear-selection").addEventListener("click", () => {
      onCountyRectangleSelect([]);
      onCensusTractRectangleSelect([]);
      polygonGraphicsLayer.removeAll();
    });

    function sendToParent(attributes) {
      onNamesRetrieved(attributes);
    }

    if (selectedAreaOption === "option1") {
      flRef2.current.visible = true;
      resultsLayerRef2.current.visible = true;
      flRef.current.visible = false;
      resultsLayerRef.current.visible = false;
    } else if (selectedAreaOption === "option2") {
      flRef2.current.visible = false;
      resultsLayerRef2.current.visible = false;
      flRef.current.visible = true;
      resultsLayerRef.current.visible = true;
    }
  }, [onNamesRetrieved, selectedAreaOption]);

  useEffect(() => {
    const fl = flRef.current;
    const resultsLayer = resultsLayerRef.current;
    if (!fl || !resultsLayer) return;

    if (selectedNameLSADs && selectedNameLSADs.length) {
      const queryFeaturesByCTNameAndCounty = async (ctName, counties) => {
        let query = fl.createQuery();
        query.where = `CT_Name = '${ctName}' AND COUNTYNAME IN ('${counties.join(
          "','"
        )}')`;
        let results = await fl.queryFeatures(query);
        return results.features;
      };

      for (let ctName of selectedNameLSADs) {
        queryFeaturesByCTNameAndCounty(ctName, selectedCountyNames)
          .then((features) => {
            let graphicsToAdd = features.map((feature) => {
              return new GraphicRef.current({
                geometry: feature.geometry,
                symbol: new SimpleFillSymbolRef.current({
                  color: "orange",
                  style: "solid",
                  outline: {
                    color: "black",
                    width: 1,
                  },
                }),
                attributes: feature.attributes,
              });
            });

            resultsLayer.addMany(graphicsToAdd);
          })
          .catch((error) => console.error("Error querying features:", error));
      }

      resultsLayer.graphics.forEach((graphic) => {
        if (!selectedNameLSADs.includes(graphic.attributes.CT_Name)) {
          resultsLayer.remove(graphic);
        }
      });
    } else {
      resultsLayer.removeAll();
    }
  }, [selectedNameLSADs, selectedCountyNames]);

  function generateColorGradient(length) {
    const colorArray = [];
    const colorCount = length;

    const startColor = [0, 0, 205]; // Blue
    const endColor = [205, 0, 0]; // Red

    if (length === 1) {
      colorArray.push(startColor);
      return colorArray;
    } else if (length === 2) {
      colorArray.push(startColor);
      colorArray.push(endColor);
      return colorArray;
    }

    for (let i = 0; i < colorCount; i++) {
      const ratio = i / (colorCount - 1);
      const color = [
        startColor[0] * (1 - ratio) + endColor[0] * ratio,
        startColor[1] * (1 - ratio) + endColor[1] * ratio,
        startColor[2] * (1 - ratio) + endColor[2] * ratio,
      ];
      colorArray.push(
        `rgb(${Math.round(color[0])}, ${Math.round(color[1])}, ${Math.round(
          color[2]
        )})`
      );
    }

    return colorArray;
  }

  useEffect(() => {
    const fl = flRef2.current;
    const resultsLayer = resultsLayerRef2.current;
    if (!fl || !resultsLayer) return;

    if (selectedCountyNames && selectedCountyNames.length) {
      const queryFeaturesByCountyName = async (countyName) => {
        let query = fl.createQuery();
        query.where = `COUNTYNAME = '${countyName}'`;
        let results = await fl.queryFeatures(query);
        return results.features;
      };

      const weightedSumsArray = Object.entries(weightedSums);

      // Sort the array based on the values in descending order
      weightedSumsArray.sort((a, b) => b[1] - a[1]);

      // Extract the sorted county names (keys)
      const sortedCountyNames = weightedSumsArray.map(([county]) => county);

      const colors = generateColorGradient(sortedCountyNames.length);

      for (let countyName of selectedCountyNames) {
        queryFeaturesByCountyName(countyName)
          .then((features) => {
            features.sort(
              (a, b) => b.attributes.OBJECTID - a.attributes.OBJECTID
            );

            let weightIndex = sortedCountyNames.indexOf(countyName);
            let color;

            if (weightIndex !== -1) {
              color = colors[weightIndex];
            } else {
              color = "#FFA500"; // Orange
            }

            let graphicsToAdd = features.map((feature) => {
              return new GraphicRef.current({
                geometry: feature.geometry,
                symbol: new SimpleFillSymbolRef.current({
                  color: color,
                  style: "solid",
                  outline: {
                    color: "black",
                    width: 1,
                  },
                }),
                attributes: feature.attributes,
              });
            });

            resultsLayer.addMany(graphicsToAdd);
          })
          .catch((error) => console.error("Error querying features:", error));
      }

      resultsLayer.graphics.forEach((graphic) => {
        if (!selectedCountyNames.includes(graphic.attributes.COUNTYNAME)) {
          resultsLayer.remove(graphic);
        }
      });

      onGradientColorChange(colors);
    } else {
      resultsLayer.removeAll();
    }
  }, [weightedSums, selectedCountyNames]);

  async function fetchAllFeatures(fl) {
    let features = [];
    let start = 0;
    const num = 1000;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      let query = fl.createQuery();
      query.start = start;
      query.num = num;

      let results = await fl.queryFeatures(query);

      if (results.features.length === 0) {
        break;
      }

      features = features.concat(results.features);
      start += num;
    }
    return features;
  }

  return (
    <>
      <div id="viewDiv" ref={mapDiv}></div>
      <button
        onClick={() => togglePrint()}
        style={{
          position: "absolute",
          top: "15px",
          right: "15px",
          zIndex: 1,
          padding: "5px 10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          cursor: "pointer",
        }}
      >
        {printVisible ? <CloseIcon /> : <PrintIcon />}
      </button>
      <div
        id="select-by-rectangle"
        className="esri-widget esri-widget--button esri-widget esri-interactive"
        title="Select features by rectangle"
      >
        <span className="esri-icon-checkbox-unchecked"></span>
      </div>
      <div
        id="clear-selection"
        className="esri-widget esri-widget--button esri-widget esri-interactive"
        title="Clear selection"
      >
        <span className="esri-icon-erase"></span>
      </div>
    </>
  );
};

export default EsriMap;
