// export const APPLICATION_URL = process.env.REACT_APP_API_URL;
export const APPLICATION_URL = "https://backend.4d-land.com";
// export const APPLICATION_URL = "http://localhost:8080";

export const DASHBOARD_BLUE = "#264E73";
export const DASHBOARD_ORANGE = "#FD9426";

export const stateAbbreviations = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  DC: "District of Columbia",
  PR: "Puerto Rico",
  VI: "Virgin Islands",
  AS: "American Samoa",
  GU: "Guam",
  MP: "Northern Mariana Islands",
};

export const crsRatings = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

export const NJCountyCodes = {
  34009: "Cape May",
  34011: "Cumberland",
  34033: "Salem",
  34001: "Atlantic",
  34015: "Gloucester",
  34007: "Camden",
  34005: "Burlington",
  34029: "Ocean",
  34025: "Monmouth",
  34021: "Mercer",
  34019: "Hunterdon",
  34023: "Middlesex",
  34035: "Somerset",
  34039: "Union",
  34041: "Warren",
  34037: "Sussex",
  34027: "Morris",
  34013: "Essex",
  34017: "Hudson",
  34031: "Passaic",
  34003: "Bergen",
};