import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { APPLICATION_URL } from "../constants";
import axios from "axios";

const RegisterWrapper = styled.div`
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .mb-3 {
  }
`;

const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleRegistration = async () => {
    axios
      .post(
        `${APPLICATION_URL}/api/user`,
        {
          email: email,
          username: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <RegisterWrapper>
      <Form style={{ width: 300 }} onSubmit={() => handleRegistration()}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../assets/tr_initials.png")}
            alt="Timbalier Resources Logo"
            style={{ width: 50, paddingRight: 10 }}
          />
          <h2>Register</h2>
        </div>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            style={{ height: 50 }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            style={{ height: 50 }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ height: 50 }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Button
            style={{
              background: "none",
              color: "black",
              border: "1px solid black",
            }}
            onClick={() => navigate("/login")}
          >
            Already have an account? Login
          </Button>
        </Form.Group>
        <Button variant="primary" onClick={() => handleRegistration()}>
          Submit
        </Button>
      </Form>
    </RegisterWrapper>
  );
};

export default Register;
