import React, { useEffect } from "react";
import styled from "styled-components";
import withAuth from "../components/4d-land/withAuth";

const AboutWrapper = styled.div`
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const About = () => {
  return (
    <>
      <AboutWrapper>
        <h4>4D Land About</h4>
      </AboutWrapper>
    </>
  );
};

export default withAuth(About);
