import React, { useState } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { APPLICATION_URL } from "../constants";
import axios from "axios";

import { LoginInputProps } from "../typeInterfaces/crsProps";
import { GoogleLogin } from "@react-oauth/google";

const LoginWrapper = styled.div`
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .mb-3 {
  }
`;

const Login: React.FC<LoginInputProps> = ({ handleAuthTokenChange }) => {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const responseMessage = (response: any) => {
    handleAuthTokenChange(response.credential);
    localStorage.setItem("token", response.credential);
    navigate("/home");
  };
  const errorMessage = () => {
    console.log("ERROR");
  };

  const handleLogin = () => {
    axios
      .post(
        `${APPLICATION_URL}/api/login`,
        {
          email: emailOrUsername,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("token", res.data["token"]);
        handleAuthTokenChange(localStorage.getItem("token"));
        navigate("/home");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <LoginWrapper>
      <Form
        style={{
          width: 500,
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
          border: "1px solid black",
          padding: 50,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../assets/tr_initials.png")}
            alt="Timbalier Resources Logo"
            style={{ width: 50, paddingRight: 10 }}
          />
          <h2>Login</h2>
        </div>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter email"
            onChange={(e) => setEmailOrUsername(e.target.value)}
            style={{ height: 50 }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ height: 50 }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Button
            style={{
              background: "none",
              color: "black",
              border: "1px solid black",
            }}
            onClick={() => navigate("/register")}
          >
            No account? Register
          </Button>
        </Form.Group>
        <Button variant="primary" onClick={() => handleLogin()}>
          Login
        </Button>
        <hr />
        <Form.Group style={{ paddingBottom: 10, margin: "auto" }}>
          <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        </Form.Group>
      </Form>
    </LoginWrapper>
  );
};

export default Login;
