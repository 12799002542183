import React from "react";
import styled from "styled-components";

import { DASHBOARD_BLUE } from "../../constants";
import { CrsBoxProps } from "../../typeInterfaces/crsProps";

const CRSWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${DASHBOARD_BLUE};
  border-radius: 5px;
  text-align: left;
  margin-top: 20px;
  color: ${DASHBOARD_BLUE};
`;

const CrsStatusLine = styled.div`
  display: flex;
  font-size: 14px;

  .leftText {
    margin-right: 5px;
    font-weight: bold;
  }
`;

const CrsText = styled.div`
  flex: 1;
  text-align: left;
`;

const CRSStatus: React.FC<CrsBoxProps> = ({
  crsStatus,
  rating,
  ratingDate,
}) => {
  return (
    <CRSWrapper>
      <div
        style={{
          backgroundColor: DASHBOARD_BLUE,
          color: "white",
          padding: "10px",
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          fontSize: 16,
        }}
      >
        CRS Status
      </div>
      <div style={{ padding: 20 }}>
        <CrsStatusLine>
          <CrsText id="state" className="leftText">
            Enrolled:
          </CrsText>
          <CrsText>{crsStatus ? "Active" : "Inactive"}</CrsText>
        </CrsStatusLine>
        <CrsStatusLine>
          <CrsText id="city" className="leftText">
            Rating:
          </CrsText>
          <CrsText>{rating}</CrsText>
        </CrsStatusLine>
        <CrsStatusLine>
          <CrsText id="city" className="leftText">
            Date of Rating:
          </CrsText>
          <CrsText>{ratingDate}</CrsText>
        </CrsStatusLine>
      </div>
    </CRSWrapper>
  );
};

export default CRSStatus;
