import React from "react";
import styled from "styled-components";
import withAuth from "../components/4d-land/withAuth";

const HomeWrapper = styled.div`
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Home = () => {
  return (
    <>
      <HomeWrapper>
        <h4>4D Land Home</h4>
      </HomeWrapper>
    </>
  );
};

export default withAuth(Home);