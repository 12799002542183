import React from "react";
import styled from "styled-components";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { DASHBOARD_BLUE, DASHBOARD_ORANGE } from "../../constants";
import { NavbarInputProps } from "../../typeInterfaces/crsProps";

const Nav = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  background-color: ${DASHBOARD_BLUE};
  position: absolute;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;

  .navbar-button {
    &:hover {
      background-color: ${DASHBOARD_ORANGE};
      color: white;
    }
  }
`;

const Navbar: React.FC<NavbarInputProps> = ({
  authToken,
  handleAuthTokenChange,
}) => {
  const navigate = useNavigate();

  return (
    <Nav>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: authToken ? "space-between" : "flex-end",
        }}
      >
        {authToken && (
          <>
            {" "}
            <Button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => navigate("/home")}
            >
              Home
            </Button>
            <Button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => navigate("/about")}
            >
              About
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                Apps
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ padding: 0 }}>
                <Dropdown.Item
                  href="/crs-dashboard"
                  style={{
                    whiteSpace: "normal",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                  className="navbar-button"
                >
                  CRS Assessment Tool
                </Dropdown.Item>
                <Dropdown.Item
                  href="/nj-macro"
                  style={{
                    whiteSpace: "normal",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                  className="navbar-button"
                >
                  New Jersey Macro Tool
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        {authToken ? (
          <Button
            style={{
              backgroundColor: "white",
              border: "none",
              color: DASHBOARD_ORANGE,
            }}
            onClick={() => {
              navigate("/login");
              localStorage.removeItem("token");
              handleAuthTokenChange(localStorage.getItem("token"));
            }}
          >
            Log Out
          </Button>
        ) : (
          <Button
            style={{
              backgroundColor: "white",
              border: "none",
              color: DASHBOARD_ORANGE,
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </Button>
        )}
      </div>
    </Nav>
  );
};

export default Navbar;
