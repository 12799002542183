import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";

// Import exporting module
import exporting from "highcharts/modules/exporting";
exporting(Highcharts);

const StyledChart = styled.div``;

const BarChart = ({
  futureFloodRiskData,
  historicalClaimsData,
  historicalGrantAllocationsData,
  socioeconomicData,
  historicalClaimSum,
  historicalGrantSum,
  socioeconomicSum,
  // futureFloodRiskWeight,
  historicalClaimWeight,
  historicalGrantWeight,
  socioeconomicWeight,
}) => {
  const seriesData = futureFloodRiskData.length &&
    historicalClaimsData.length &&
    historicalGrantAllocationsData.length &&
    socioeconomicData.length && [
      {
        name: "Future Flood Risk",
        data: futureFloodRiskData.map((county) => county.floodRisk),
      },
      {
        name: "Historical Claims",
        data: historicalClaimsData.map(
          (county) =>
            (county.policyCountSum / historicalClaimSum) * historicalClaimWeight
        ),
      },
      {
        name: "Historical Grant Allocations",
        data: historicalGrantAllocationsData.map(
          (county) =>
            (county.propertiesCount / historicalGrantSum) *
            historicalGrantWeight
        ),
      },
      {
        name: "Socioeconomic Vulnerabilities",
        data: socioeconomicData.map(
          (county) =>
            ((county.disadvantagedCount["Disadvantaged"] +
              county.disadvantagedCount["Not Disadvantaged"]) /
              socioeconomicSum) *
            socioeconomicWeight
        ),
      },
    ];

  const categories = futureFloodRiskData.map((county) => county.name);

  const options = {
    chart: {
      type: "column",
      width: 600,
      height: 400,
    },
    title: {
      text: `Relative Percentages of Analysis Categories per County`,
    },
    xAxis: {
      categories: categories,
      title: {
        text: "County",
      },
    },
    yAxis: {
      title: {
        text: "Percentage Weight",
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const point = this.point;

        return `
          <div>${point.category}</div>
          <span style="color: ${
            point.series.color
          }; font-size: 12px;">&#9679;</span> <b>${
          point.series.name
        }</b>: ${point.y.toFixed(3)}
        `;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: seriesData,
  };

  return (
    <StyledChart>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </StyledChart>
  );
};

export default BarChart;
