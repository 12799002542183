import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DASHBOARD_BLUE, DASHBOARD_ORANGE } from "../../constants";
import styled from "styled-components";

import exporting from "highcharts/modules/exporting";

exporting(Highcharts);

interface BarChartProps {
  data: { label: string; value: number[] }[];
  seriesNum: number;
  scoringData: object | undefined;
  categoryData: object | undefined;
}

const StyledChart = styled.div``;

const BarChart: React.FC<BarChartProps> = ({
  data,
  seriesNum,
  scoringData,
  categoryData,
}) => {
  const chartRef = useRef(null);

  const toggleFullScreen = () => {
    if (chartRef.current) {
      new Highcharts.Fullscreen();
    }
  };

  toggleFullScreen();

  const categories = data.map((item) => item.label);

  const seriesData = data[0].value.map((_, index) => ({
    name: index === 0 ? "Max" : index === 1 ? "Average" : "Actual",
    data: data.map((item) => item.value[index]),
    color: index === 0 ? "#D9D9D9" : index === 1 ? DASHBOARD_BLUE : DASHBOARD_ORANGE,
  })) as Highcharts.SeriesOptionsType[];

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      width: 600,
      height: 400,
    },
    title: {
      text: `CRS Series ${seriesNum} Data`,
    },
    xAxis: {
      categories: categories,
      title: {
        text: "CRS Series",
      },
    },
    yAxis: {
      title: {
        text: "Score",
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const point = this.point;
        const seriesColor = point.series.color || "black";
        const currentSeriesCategoricalData =
          categoryData && categoryData[`c${point.category as keyof object}`];
        if (point.series.name === "Actual") {
          return `   
            <div style="z-index: 9999; overflow: auto;">         
            <div>${point.category}</div>
            <div>
              <span style="color: ${seriesColor}; font-size: 12px;">&#9679;</span> ${
            point.series.name
          }: ${point.y}
            </div>
            <div>Year_${point.category}: ${
            scoringData
              ? scoringData[`Year_${point.category}` as keyof object]
              : "N/A"
          }</div>
          <div>Categorical Scores</div>
          <div>--------------------------</div>
          <div>
          ${
            currentSeriesCategoricalData &&
            Object.values(currentSeriesCategoricalData)
              .map((category) => {
                if (Array.isArray(category) && category.length === 2) {
                  const categoryKey = category[0];
                  const categoryValue = category[1];
                  return `${categoryKey}: ${
                    typeof categoryValue === "object"
                      ? Object.entries(categoryValue)
                          .map(
                            ([propKey, propValue]) => `${propKey}: ${propValue}`
                          )
                          .join(", ")
                      : categoryValue
                  }`;
                } else {
                  return "Invalid category format";
                }
              })
              .join("<br/>")
          }
        </div>
          </div>
          </div>
          `;
        } else {
          return `
            <div>${point.category}</div>
            <span style="color: ${seriesColor}; font-size: 12px;">&#9679;</span> <b>${point.series.name}</b>: ${point.y}
          `;
        }
      },
    },
    series: seriesData,
  };

  return (
    <StyledChart>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </StyledChart>
  );
};

export default BarChart;
