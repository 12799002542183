import React, { useEffect, useState } from "react";
import axios from "axios";

import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import { Dropdown } from "react-bootstrap";

import LocationInput from "../components/dashboard/locationInput";
import LocationBox from "../components/dashboard/locationBox";
import CRSStatus from "../components/dashboard/crsStatus";
import Savings from "../components/dashboard/savings";
import BarChart from "../components/dashboard/crsDataHighchartsBarChart";

import "../css/dashboard.css";
import { APPLICATION_URL } from "../constants";
import withAuth from "../components/4d-land/withAuth";

interface DataItem {
  label: string;
  value: number[];
}

const HamburgerMenu = styled(MenuIcon)`
  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s;
  }
`;

const LeftMenu = styled.div<{ navbarheight?: number }>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-right: 1px solid #dc4d01;
  min-width: 400px;
  flex: 1;
  position: sticky;
  top: ${(props) => (props.navbarheight ? `${props.navbarheight}px` : "0")};
  overflow-y: auto;
`;

const Dashboard = () => {
  const navbarHeight = document.getElementById("crsNavbarDiv")?.offsetHeight;

  const container = document.getElementById("container");

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [community, setCommunity] = useState<string | null | undefined>("");
  const [allCommunityData, setAllCommunityData] = useState<
    object | undefined
  >();
  const [states, setStates] = useState<Set<string> | undefined>(new Set());
  const [communities, setCommunities] = useState<Set<string> | undefined>(
    new Set()
  );

  // Community info tab
  const [usState, setUsState] = useState<string | null | undefined>("");
  const [communityId, setCommunityId] = useState<string | undefined>("");
  const [county, setCounty] = useState<string | undefined>("");
  const [cavDate, setCavDate] = useState<string | undefined>("");

  // CRS Status tab
  const [crsStatus, setCrsStatus] = useState<boolean | undefined>(false);
  const [rating, setRating] = useState<string | undefined>("0");
  const [ratingDate, setRatingDate] = useState<string | undefined>("");

  // CRS bar plots
  const [finalCrsSeriesData, setFinalCrsSeriesData] = useState<
    object | undefined
  >();
  const [finalCategoricalCrsSeriesData, setFinalCategoricalCrsSeriesData] =
    useState<object | undefined>();

  const [crs300SeriesData, setCrs300SeriesData] = useState<DataItem[]>([
    { label: "310", value: [116, 38, 60] },
    { label: "320", value: [90, 73, 34] },
    { label: "330", value: [350, 87, 98] },
    { label: "340", value: [80, 14, 16] },
    { label: "350", value: [125, 38, 120] },
    { label: "360", value: [110, 55, 66] },
    { label: "370", value: [110, 39, 28] },
  ]);

  const [crs400SeriesData, setCrs400SeriesData] = useState<DataItem[]>([
    { label: "410", value: [802, 60, 24] },
    { label: "420", value: [2020, 509, 800] },
    { label: "430", value: [2042, 270, 250] },
    { label: "440", value: [222, 115, 66] },
    { label: "450", value: [755, 132, 289] },
  ]);

  const [crs500SeriesData, setCrs500SeriesData] = useState<DataItem[]>([
    { label: "510", value: [622, 175, 842] },
    { label: "520", value: [2250, 195, 1234] },
    { label: "530", value: [1600, 73, 222] },
    { label: "540", value: [570, 218, 108] },
  ]);

  const [crs600SeriesData, setCrs600SeriesData] = useState<DataItem[]>([
    { label: "610", value: [395, 254, 320] },
    { label: "620", value: [235, 157, 48] },
    { label: "630", value: [160, 35, 80] },
  ]);

  const [calculationPending, setCalculationPending] = useState<boolean>(false);

  const handleCrsSeriesDataChange = (data: object | undefined) => {
    setFinalCrsSeriesData(data);
  };

  const handleCrsCategoryDataChange = (data: object | undefined) => {
    setFinalCategoricalCrsSeriesData(data);
  };

  const handleStateChange = (newState: string | null | undefined) => {
    setUsState(newState);
  };

  const handleCityChange = (newCity: string | null | undefined) => {
    setCommunity(newCity);
  };

  const [rightSideWidth, setRightSideWidth] = useState<number | undefined>(
    document.getElementById("crsDataDiv")?.offsetWidth
  );

  const crsDataEmpty =
    !finalCrsSeriesData ||
    (finalCrsSeriesData && !Object.values(finalCrsSeriesData).length);

  const handleSizeChange = () => {
    setRightSideWidth(document.getElementById("crsDataDiv")?.offsetWidth);
  };

  useEffect(() => {
    if (community) {
      setCalculationPending(true);
      setLoadingError(false);
    }
  }, [community]);

  useEffect(() => {
    window.addEventListener("resize", handleSizeChange);

    return () => {
      window.removeEventListener("resize", handleSizeChange);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const updatedCrs300SeriesData = crs300SeriesData.map((item) => {
      const labelKey = `c${item.label}`;
      const lastValue =
        (finalCrsSeriesData && finalCrsSeriesData[labelKey as keyof object]) ||
        0;
      return {
        ...item,
        value: [...item.value.slice(0, -1), lastValue],
      };
    });

    const updatedCrs400SeriesData = crs400SeriesData.map((item) => {
      const labelKey = `c${item.label}`;
      const lastValue =
        (finalCrsSeriesData && finalCrsSeriesData[labelKey as keyof object]) ||
        0;
      return {
        ...item,
        value: [...item.value.slice(0, -1), lastValue],
      };
    });

    const updatedCrs500SeriesData = crs500SeriesData.map((item) => {
      const labelKey = `c${item.label}`;
      const lastValue =
        (finalCrsSeriesData && finalCrsSeriesData[labelKey as keyof object]) ||
        0;
      return {
        ...item,
        value: [...item.value.slice(0, -1), lastValue],
      };
    });

    const updatedCrs600SeriesData = crs600SeriesData.map((item) => {
      const labelKey = `c${item.label}`;
      const lastValue =
        (finalCrsSeriesData && finalCrsSeriesData[labelKey as keyof object]) ||
        0;
      return {
        ...item,
        value: [...item.value.slice(0, -1), lastValue],
      };
    });

    setCrs300SeriesData(updatedCrs300SeriesData);
    setCrs400SeriesData(updatedCrs400SeriesData);
    setCrs500SeriesData(updatedCrs500SeriesData);
    setCrs600SeriesData(updatedCrs600SeriesData);
  }, [finalCrsSeriesData]);

  useEffect(() => {
    if (allCommunityData !== undefined) {
      const currentCommunity: object[] = Object.values(
        allCommunityData
      ).flatMap((item) =>
        item.filter(
          (subItem: object) =>
            subItem["communityName" as keyof object] === community &&
            subItem["state" as keyof object] === usState
        )
      );

      if (currentCommunity.length > 0) {
        const currentCommunityId: string[] | undefined = currentCommunity.map(
          (item: object) => item["communityIdNumber" as keyof object]
        );
        const currentCavDate: object[] | undefined = currentCommunity.map(
          (item: object) => item["classRatingEffectiveDate" as keyof object]
        );
        const currentCrsRating: string[] | undefined = currentCommunity.map(
          (item: object) => item["classRating" as keyof object]
        );
        const currentCrsRatingDate: object[] | undefined = currentCommunity.map(
          (item: object) => item["classRatingEffectiveDate" as keyof object]
        );
        const currentCounty: string[] | undefined = currentCommunity.map(
          (item: object) => item["county" as keyof object]
        );

        setCommunityId(currentCommunityId[0]);

        if (currentCounty[0].includes("/")) {
          setCounty(currentCounty[0].split("/")[0]);
        } else {
          setCounty(currentCounty[0]);
        }
        setCavDate(
          currentCavDate[0] !== null
            ? new Date(currentCavDate[0] as Date).toLocaleDateString()
            : "N/A"
        );
        if (currentCrsRating[0] !== null) {
          setRating(currentCrsRating[0]);
          setCrsStatus(true);
          setRatingDate(
            new Date(currentCrsRatingDate[0] as Date).toLocaleDateString()
          );
        } else {
          setRating("N/A");
          setCrsStatus(false);
          setRatingDate("N/A");
        }
      }
    }
  }, [community, allCommunityData]);

  useEffect(() => {
    let isDataLoaded = false;

    const timeout = setTimeout(() => {
      if (!isDataLoaded) {
        setLoadingData(false);
        console.log("Data loading timed out");
      }
    }, 1000);

    const fetchAllCommunityInfo = async () => {
      try {
        const response = await axios.get(
          `${APPLICATION_URL}/api/fetchFemaCommunityStatusData`
        );
        setAllCommunityData(response.data);
        setLoadingData(false);
        isDataLoaded = true;
        clearTimeout(timeout);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAllCommunityInfo();

    // Clean-up function to clear the timeout if component unmounts or data is loaded
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (allCommunityData !== undefined) {
      const stateSet: Set<string> = new Set();
      Object.values(allCommunityData).forEach((item) => {
        item.forEach((subItem: object) => {
          const state = subItem["state" as keyof object];
          if (state) {
            stateSet.add(state);
          }
        });
      });

      const communitySet: Set<string> = new Set();
      Object.values(allCommunityData).forEach((item) => {
        item.forEach((subItem: object) => {
          const community = subItem["communityName" as keyof object];
          if (community && subItem["state" as keyof object] === usState) {
            communitySet.add(community);
          }
        });
      });

      setStates(stateSet);
      setCommunities(communitySet);
    }
  }, [usState, allCommunityData]);

  useEffect(() => {
    setCommunityId("N/A");
  }, [usState]);

  return (
    <div
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
      id="container"
    >
      {loadingData ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            style={{ width: "5%", marginBottom: "2%" }}
            src={require("../assets/crs_loader.gif")}
            alt="Loading gif"
          />
          <div>Loading...</div>
        </div>
      ) : (
        <>
          <div
            id="crsNavbarDiv"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{ display: "flex", width: "40%", alignItems: "center" }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <HamburgerMenu
                    style={{
                      fontSize: 50,
                      color: "#264E73",
                      marginRight: "2%",
                    }}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/home" style={{ whiteSpace: "normal" }}>
                    Home
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/crs-dashboard"
                    style={{ whiteSpace: "normal" }}
                  >
                    CRS Assessment Tool
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/nj-macro"
                    style={{ whiteSpace: "normal" }}
                  >
                    New Jersey Macro Tool
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div style={{ fontSize: 24, color: "#264E73" }}>
                CRS Assessment Tool
              </div>
            </div>
            <img
              src={require("../assets/TR (color).png")}
              style={{
                width: "15%",
                height: "8%",
                display: "flex",
                alignItems: "flex-end",
              }}
              alt="TR logo"
            />
          </div>
          <div
            id="crsDataDiv"
            style={{
              height:
                container && container.scrollHeight > container.clientHeight
                  ? "auto"
                  : `calc(100vh - ${navbarHeight}px)`,
              display: "flex",
            }}
            className="content"
          >
            <LeftMenu
              id="left-menu"
              navbarheight={navbarHeight}
              style={{
                height:
                  container && container.scrollHeight > container.clientHeight
                    ? "auto"
                    : `calc(100vh - ${navbarHeight}px)`,
              }}
            >
              <>
                <LocationInput
                  stateList={states}
                  communityList={communities}
                  onStateChange={handleStateChange}
                  onCityChange={handleCityChange}
                  state={usState}
                  city={community}
                />
                <LocationBox
                  state={usState}
                  city={community}
                  communityId={communityId}
                  cavDate={cavDate}
                />
                <CRSStatus
                  crsStatus={crsStatus}
                  rating={rating}
                  ratingDate={ratingDate}
                />
                <Savings
                  state={usState}
                  community={community}
                  communityId={communityId}
                  county={county}
                  crsRating={rating}
                  onCalculationPendingChange={setCalculationPending}
                  onCrsSeriesDataChange={handleCrsSeriesDataChange}
                  onCrsCategoryDataChange={handleCrsCategoryDataChange}
                  onSavingsDataError={setLoadingError}
                />{" "}
              </>
            </LeftMenu>
            {/* </Resizable> */}
            <div
              style={{
                backgroundColor: "white",
                width: "75%",
                display:
                  rightSideWidth && rightSideWidth >= 1600 ? "flex" : "block",
                flexDirection: "column",
                justifyContent: "center",
                overflowY: "auto",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2 style={{ paddingTop: "2%", width: "40%" }}>
                  CRS Credit Points
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display:
                      rightSideWidth && rightSideWidth >= 1600
                        ? "flex"
                        : "block",
                  }}
                >
                  {loadingError ? (
                    <h5 style={{ color: "red" }}>
                      Cannot find community in dataset.
                    </h5>
                  ) : calculationPending ? (
                    <h5 style={{ color: "red" }}>
                      Please click the "Calculate" button to see savings for the
                      new community.
                    </h5>
                  ) : community && crsDataEmpty ? (
                    <h5 style={{ color: "red" }}>
                      No CRS data found for the selected community!
                    </h5>
                  ) : !community ? (
                    <h5 style={{ color: "red" }}>No community selected!</h5>
                  ) : null}
                  {community && !calculationPending ? (
                    <>
                      <div>
                        <BarChart
                          data={crs300SeriesData}
                          seriesNum={300}
                          scoringData={finalCrsSeriesData}
                          categoryData={finalCategoricalCrsSeriesData}
                        />
                        <BarChart
                          data={crs500SeriesData}
                          seriesNum={500}
                          scoringData={finalCrsSeriesData}
                          categoryData={finalCategoricalCrsSeriesData}
                        />
                      </div>
                      <div>
                        <BarChart
                          data={crs400SeriesData}
                          seriesNum={400}
                          scoringData={finalCrsSeriesData}
                          categoryData={finalCategoricalCrsSeriesData}
                        />
                        <BarChart
                          data={crs600SeriesData}
                          seriesNum={600}
                          scoringData={finalCrsSeriesData}
                          categoryData={finalCategoricalCrsSeriesData}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withAuth(Dashboard);
