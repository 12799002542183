import React, { useEffect, useState } from "react";
import "./App.css";
import Dashboard from "./screens/dashboard";
import Macro from "./screens/nj-macro";
import Home from "./screens/home";
import About from "./screens/about";
import Navbar from "./components/4d-land/navbar";
import Login from "./screens/login";
import Register from "./screens/register";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const location = useLocation();

  const [authToken, setAuthToken] = useState(localStorage.getItem("token"));

  return (
    <div className="App">
      {location.pathname !== "/crs-dashboard" &&
        location.pathname !== "/nj-macro" && (
          <Navbar authToken={authToken} handleAuthTokenChange={setAuthToken} />
        )}
      <Routes>
        <Route
          path="/"
          element={<Login handleAuthTokenChange={setAuthToken} />}
        ></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route
          path="/crs-dashboard"
          element={<Dashboard handleAuthTokenChange={setAuthToken} />}
        ></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/nj-macro" element={<Macro />}></Route>
        <Route
          path="/login"
          element={<Login handleAuthTokenChange={setAuthToken} />}
        ></Route>
        <Route path="/register" element={<Register />}></Route>
      </Routes>
    </div>
  );
}

export default App;
