import React from "react";
import "../../css/nj-macro/SliderComponent.css";

function SliderComponent(props) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    props.onChange(newValue);
  };

  return (
    <div className="slider-container">
      <div className="slider">
        <div
          className="track"
          style={{ width: `${props.value * 100}%`, height: "6px" }}
        ></div>
        <input
          type="range"
          min="0"
          max="1"
          step=".01"
          value={props.value}
          onChange={handleChange}
          className="custom-slider"
        />
      </div>
      <div className="slider-labels">
        <span>0</span>
        <span>{Number(props.value).toFixed(2)}</span>
        <span>1</span>
      </div>
    </div>
  );
}

export default SliderComponent;
